import React, { useEffect, useState } from 'react';
import * as apiFunction from '../../../services/apiFunctions';
import * as strapiFunction from '../../../services/strapiFunctions';
import * as url from '../../../config/environmentURL';
import { useAuth } from '../../../services/useAuth';
import './Section3.scss';

export const Section3 = (props) => {

    const { user } = useAuth();
    const [guideMoods, setGuideMoods] = useState({});

    useEffect(() => {
        async function getUserGuideData() {
            let guideResponse = await apiFunction.getAPIData(url.currentURL + "guide/" + user);
            if (guideResponse.success) {
                let guideData = guideResponse.responseData.data.results.guideData;
                let guideMoodResponse = await strapiFunction.getGuideMoodData(guideData.guide);

                let guideMoodDataArray = guideMoodResponse.responseData.data.data;
                let guideMoodsObj = {};
                for (let index in guideMoodDataArray) {
                    let guideMoodData = guideMoodDataArray[index].attributes;
                    guideMoodsObj = {
                        ...guideMoodsObj,
                        [guideMoodData.Mood]: guideMoodData.MoodImage.data.attributes.url
                    }
                }
                setGuideMoods(guideMoodsObj);
            }
        }
        getUserGuideData();

    }, [user]);

    // useEffect(() => {
    //     console.log(props.slideContent);
    // }, [props]);
    return (
        <div className="section3-container">
            <div className="content-container">
                <h1 className="content-header">{props.slideContent.Heading}</h1>
                <pre className="content-description">{props.slideContent.Description}</pre>
            </div>
            <div className="speech-bubble-container">
                <div className="speech-bubble">
                    <h2 className="speech-bubble-header">{props.slideContent.SpeechBubble.Heading}</h2>
                    <p className="speech-bubble-description">{props.slideContent.SpeechBubble.Description}</p>
                </div>
                <img src={guideMoods[props.slideContent.SpeechBubble.Mood]} alt={guideMoods[props.slideContent.SpeechBubble.Mood]} className="speech-bubble-img"></img>
            </div>
        </div>
    )
}