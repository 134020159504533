import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import * as strapiFunctions from '../../services/strapiFunctions';
import './LandingPage.scss';
import iconLogo from '../../assets/logo/icon-logo.svg';

export const LandingPage = () => {

    const [landingPageData, setLandingPageData] = useState();

    const [isTopNavBarCollapsed, setIsTopNavBarCollapsed] = useState(true);

    const navigate = useNavigate();

    const navigateToLink = (link) => {
        navigate(link);
    }

    const toggleTopNavBar = () => {
        setIsTopNavBarCollapsed(!isTopNavBarCollapsed);
    }

    useEffect(() => {
        async function getLandingPageDetails() {
            let response = await strapiFunctions.getLandingPageData();
            let landingPageResponse = response.responseData.data.data.attributes;

            let landingPageDataObj = {};

            let introductionSectionData = landingPageResponse["Introduction"];
            let introductionSectionDataObj = {};
            introductionSectionDataObj["header"] = introductionSectionData["Header"];
            introductionSectionDataObj["description"] = introductionSectionData["Description"];
            introductionSectionDataObj["illustration"] = introductionSectionData["Illustration"].data.attributes;

            landingPageDataObj["introduction"] = introductionSectionDataObj;

            let exploreSectionData = landingPageResponse["Explore"];
            let exploreSectionDataObj = {};
            exploreSectionDataObj["header"] = exploreSectionData["Header"];
            exploreSectionDataObj["description"] = exploreSectionData["Description"];
            exploreSectionDataObj["illustration"] = exploreSectionData["Illustration"].data.attributes;
            exploreSectionDataObj["gameTagline"] = exploreSectionData["GameTagline"];
            exploreSectionDataObj["gameIllustration"] = exploreSectionData["GameIllustration"].data.attributes;
            exploreSectionDataObj["targetIllustration"] = exploreSectionData["TargetIllustration"].data.attributes;
            let recommendedCoursesData = exploreSectionData["recommended_courses"].data;
            let recommendedCoursesArray = [];
            for (let index in recommendedCoursesData) {
                let recommendedCourseData = recommendedCoursesData[index].attributes;
                let recommendedCourseObj = {};
                recommendedCourseObj["name"] = recommendedCourseData["Name"];
                recommendedCourseObj["illustration"] = recommendedCourseData["Illustration"].data.attributes;
                recommendedCoursesArray.push(recommendedCourseObj);
            }
            exploreSectionDataObj["recommendedCourses"] = recommendedCoursesArray;

            landingPageDataObj["explore"] = exploreSectionDataObj;

            let infoSectionData = landingPageResponse["Info"];
            let infoSectionDataObj = {};
            infoSectionDataObj["header"] = infoSectionData["Header"];
            let infoCardsData = infoSectionData["InfoCards"];
            let infoCardsArray = [];
            for (let index in infoCardsData) {
                let infoCardData = infoCardsData[index];
                let infoCardObj = {};
                infoCardObj["number"] = infoCardData["Number"];
                infoCardObj["description"] = infoCardData["Description"];
                infoCardObj["illustration"] = infoCardData["Illustration"].data.attributes;
                infoCardsArray.push(infoCardObj);
            }
            infoSectionDataObj["infoCards"] = infoCardsArray;

            landingPageDataObj["info"] = infoSectionDataObj;

            setLandingPageData(landingPageDataObj);
        }

        getLandingPageDetails();
    }, []);

    return (
        <div className="landing-page-container">
            <div className={`top-nav-bar ${!isTopNavBarCollapsed ? "top-nav-bar-expanded" : ""}`}>
                <div className="top-nav-bar-action-container">
                    <img src={iconLogo} alt="logo-icon" className="top-nav-logo-img" onClick={() => { navigateToLink("/") }}></img>
                    <div className="toggle-nav-btn" onClick={toggleTopNavBar}>
                        <span className={`toggle-nav-btn-icon ${isTopNavBarCollapsed ? "toggle-nav-btn-icon-collapsed" : "toggle-nav-btn-icon-expanded"}`}></span>
                    </div>
                </div>
                <div className={`top-nav-options-container ${isTopNavBarCollapsed ? "top-nav-options-container-collapsed" : ""}`}>
                    <h1 className="top-nav-header">Missed in School</h1>
                    <div className="login-signup-options-container">
                        <p className="top-nav-option" onClick={() => { navigateToLink("/login") }}>Log In</p>
                        <span className="top-nav-option-label">or</span>
                        <p className="signup-option" onClick={() => { navigateToLink("/signup") }}>Sign Up</p>
                    </div>
                    {/* <div className="top-nav-options">
                        <p className="top-nav-option" onClick={() => { navigateToLink("/explore-courses") }}>Courses</p>
                        <p className="top-nav-option" onClick={() => { navigateToLink("/explore-games") }}>Games</p>
                        <p className="top-nav-option">About Us</p>
                    </div> */}
                </div>
            </div>
            {landingPageData &&
                <div className="introduction-section">
                    <div className="nav-bar">
                        <div className="nav-options">
                            <img src={iconLogo} alt="logo-icon" className="nav-logo-img" onClick={() => { navigateToLink("/") }}></img>
                            {/* <p className="nav-option" onClick={() => { navigateToLink("/explore-courses") }}>Courses</p>
                            <p className="nav-option" onClick={() => { navigateToLink("/explore-games") }}>Games</p>
                            <p className="nav-option">About Us</p> */}
                        </div>
                        <div className="nav-options">
                            <p className="nav-option" onClick={() => { navigateToLink("/login") }}>Log In</p>
                            <p className="signup-option" onClick={() => { navigateToLink("/signup") }}>Sign Up</p>
                        </div>
                    </div>



                    <div className="introduction-container">
                        <div className="introduction-content-container">
                            <h1 className="introduction-header">{landingPageData.introduction.header}</h1>
                            <pre className="introduction-description">
                                {landingPageData.introduction.description}
                            </pre>
                            <button className="introduction-action-btn" onClick={() => { navigateToLink("/signup") }}>Get Started<span className="introduction-action-btn-icon"></span></button>
                        </div>
                        <div className="introduction-illustration-container">
                            <img src={landingPageData.introduction.illustration.url} alt={landingPageData.introduction.illustration.alternativeText} className="introduction-illustration-img"></img>
                        </div>
                    </div>
                </div>
            }
            {
                landingPageData &&
                <div className="explore-section">
                    <div className="explore-target-illustration-container">
                        <img src={landingPageData.explore.targetIllustration.url} alt={landingPageData.explore.targetIllustration.alternativeText} className="explore-target-illustration-img"></img>
                    </div>
                    <h1 className="explore-header">{landingPageData.explore.header}</h1>
                    <div className="explore-content-container">
                        <div className="explore-illustration-container">
                            <img src={landingPageData.explore.illustration.url} alt={landingPageData.explore.illustration.alternativeText} className="explore-illustration-img"></img>
                        </div>
                        <div className="explore-description-container">
                            <pre className="explore-description">{landingPageData.explore.description}</pre>
                        </div>
                    </div>
                    <div className="explore-recommended-courses-container">
                        <p className="explore-recommended-courses-label">Checkout some of our popular courses:</p>
                        <div className="recommended-courses-container">
                            {landingPageData.explore.recommendedCourses.length > 0 && landingPageData.explore.recommendedCourses.map((course) =>
                                <div className="recommended-course-card" key={course.name} onClick={() => { navigateToLink("/signup") }}>
                                    <div className="recommended-course-details-container">
                                        <div className="illustration-container">
                                            <img src={course.illustration.url} alt={course.illustration.alternativeText} className="illustration-img"></img>
                                        </div>
                                        <div className="recommended-course-detail-container">
                                            <h2 className="recommended-course-name">{course.name}</h2>
                                        </div>
                                    </div>
                                    <div className="recommended-course-action-btn-container">
                                        <button className="recommended-course-action-btn"></button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            {
                landingPageData &&
                <div className="games-section">
                    <p className="games-tagline">{landingPageData.explore.gameTagline}</p>
                    <button className="games-action-btn" onClick={() => { navigateToLink("/signup") }}>Let's Play!<span className="games-action-btn-icon"></span></button>
                    <div className="games-illustration-container">
                        <img src={landingPageData.explore.gameIllustration.url} alt={landingPageData.explore.gameIllustration.alternativeText} className="games-illustration-img"></img>
                    </div>
                </div>
            }
            {
                landingPageData &&
                <div className="info-section">
                    <h1 className="info-header">{landingPageData.info.header}</h1>
                    <div className="info-cards-container">
                        {landingPageData.info.infoCards.length > 0 && landingPageData.info.infoCards.map((infoCard) =>
                            <div className="info-card" key={infoCard.number}>
                                <p className="info-card-number">{infoCard.number}</p>
                                <p className="info-card-description">{infoCard.description}</p>
                                <img src={infoCard.illustration.url} alt={infoCard.illustration.alternativeText} className="info-card-illustration-img"></img>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div >
    );
}