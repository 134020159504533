/* eslint-disable no-eval */
import React, { useState, useEffect, useCallback } from 'react';
import * as apiFunctions from '../../services/apiFunctions';
import * as strapiFunction from '../../services/strapiFunctions';
import { useAuth } from '../../services/useAuth';
import { useNavigate, useSearchParams } from "react-router-dom";
import './LearnersQuizPage.scss';
import * as url from '../../config/environmentURL';
import { OptionsSquareLayout } from '../../components/learners-quiz-options/options-square-layout/OptionsSquareLayout';
import { OptionsRectangularLayout } from '../../components/learners-quiz-options/options-rectangular-layout/OptionsRectangularLayout';
import { CourseCards } from '../../components/course-cards/CourseCards';

export const LearnersQuizPage = () => {

    const { user } = useAuth();
    const navigate = useNavigate();

    const [learnersQuizData, setLearnersQuizData] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isQuizStarted, setIsQuizStarted] = useState(false);
    const [isQuizFinished, setIsQuizFinished] = useState(false);
    const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
    const [courseData, setCourseData] = useState([]);
    const [coursePriorityList, setCoursePriorityList] = useState({});
    const [recommendedCourses, setRecommendedCourses] = useState([]);
    const [quizResults, setQuizResults] = useState({});


    useEffect(() => {
        async function getLearnersQuizData() {
            let response = await strapiFunction.getLearnersQuizData();

            let questionDataArray = response.responseData.data.data;
            let questionArray = [];

            for (let index in questionDataArray) {
                let questionData = questionDataArray[index].attributes;

                let question = {};

                let optionsDataArray = questionData.learners_quiz_options.data;
                let optionsArray = [];

                for (let index in optionsDataArray) {
                    let optionData = optionsDataArray[index].attributes;

                    let option = {};
                    option["name"] = optionData["Name"];
                    option["points"] = optionData["Points"];
                    option["imgSrc"] = optionData["Image"].data;
                    optionsArray.push(option);
                }

                question["options"] = optionsArray;
                question["category"] = questionData["Category"];
                question["question"] = questionData["Question"];
                question["multiselect"] = questionData["Multiselect"];
                question["scoreFormula"] = questionData["ScoreFormula"];
                question["layout"] = questionData["Layout"];
                question["optionSize"] = questionData["OptionSize"];
                question["totalPoints"] = 0;

                questionArray.push(question);
            }
            setLearnersQuizData(questionArray);
        }

        async function getCourseDetails() {
            let response = await strapiFunction.getAllCourses();

            let coursesDataArray = response.responseData.data.data;
            let coursesArray = [];

            for (let index in coursesDataArray) {
                let courseData = coursesDataArray[index].attributes;
                let courseDataObj = {}
                courseDataObj["name"] = courseData["Name"];
                courseDataObj["lessonCount"] = courseData["course_lessons"].data.length;
                courseDataObj["illustration"] = courseData["Illustration"].data.attributes;
                courseDataObj["priority"] = courseData["Priority"];
                courseDataObj["verified"] = courseData["Verified"];
                coursesArray.push(courseDataObj);
            }
            setCourseData(coursesArray);
        }

        getLearnersQuizData();
        getCourseDetails();
    },
        []
    );

    useEffect(() => {
        let priorityList = {};

        for (let index in courseData) {
            let course = courseData[index];
            priorityList[course.name] = course.priority;
        }

        setCoursePriorityList(priorityList);
    }, [courseData]);

    const startQuiz = () => {
        setCurrentQuestionIndex(0);
    }

    const goToNextQuestion = useCallback(
        (points) => {
            let selected = points;
            let calculatedTotal = eval(learnersQuizData[currentQuestionIndex].scoreFormula);
            setLearnersQuizData(existingQuestions => {
                return [
                    ...existingQuestions.slice(0, currentQuestionIndex),
                    {
                        ...existingQuestions[currentQuestionIndex],
                        totalPoints: calculatedTotal
                    },
                    ...existingQuestions.slice(currentQuestionIndex + 1)
                ]
            });

            if (currentQuestionIndex < learnersQuizData.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setSearchParams({ questionIndex: currentQuestionIndex + 1 });
            }
            else {
                setIsQuizFinished(true);
                searchParams.delete('questionIndex');
                setSearchParams(searchParams);
            }
        },
        [currentQuestionIndex, learnersQuizData, searchParams, setSearchParams]
    );

    useEffect(() => {
        if (currentQuestionIndex >= 0) {
            setSearchParams({ questionIndex: currentQuestionIndex });
        }
    },
        [currentQuestionIndex, setSearchParams]
    );

    useEffect(() => {
        let questionIndexParam = searchParams.get("questionIndex");
        if (questionIndexParam) {
            let questionIndexParamValue = Number(questionIndexParam);
            if (learnersQuizData && questionIndexParamValue >= 0 && questionIndexParamValue < learnersQuizData.length) {
                setCurrentQuestionIndex(questionIndexParamValue);
                setIsQuizStarted(true);
            }
        }
        else {
            setIsQuizStarted(false);
        }
    }, [searchParams, learnersQuizData]);

    useEffect(() => {
        if (isQuizFinished) {
            async function submitQuizResults() {
                let quizResultsObj = learnersQuizData.reduce((learnersQuiz, question) => (learnersQuiz[question.category] = question.totalPoints, learnersQuiz), {});

                let requestData = {
                    user: user,
                    ...quizResultsObj
                }

                setQuizResults(quizResultsObj);

                let response = await apiFunctions.postAPIData(url.currentURL + "learners-quiz", requestData);

                if (response.success) {
                    setIsQuizSubmitted(true);
                }
            }
            submitQuizResults();
        }
    }, [isQuizFinished, learnersQuizData, navigate, user]);

    useEffect(() => {
        if (isQuizSubmitted) {

            let uniqueScores = new Set();

            for (const category in quizResults) {
                uniqueScores.add(quizResults[category]);
            }

            let sortedUniqueScores = Array.from(uniqueScores).sort((a, b) => a - b);

            let recommendedCoursesNames = [];

            Object.filter = (obj, predicate) =>
                Object.fromEntries(Object.entries(obj).filter(predicate));

            for (let index in sortedUniqueScores) {
                let categories = Object.keys(Object.filter(quizResults, ([category, totalPoints]) => totalPoints === sortedUniqueScores[index]));

                if (recommendedCoursesNames.length + categories.length > 3) {
                    categories = categories.sort((a, b) => coursePriorityList[a] - coursePriorityList[b]);
                    let remainingSpots = 3 - recommendedCoursesNames.length;
                    categories = categories.slice(0, remainingSpots);
                }

                recommendedCoursesNames = recommendedCoursesNames.concat(categories);

                if (recommendedCoursesNames.length === 3) {
                    break;
                }
            }

            let recommendedCoursesArray = courseData.filter(course => recommendedCoursesNames.includes(course.name));

            setRecommendedCourses(recommendedCoursesArray);
        }
    }, [isQuizSubmitted, quizResults, coursePriorityList, courseData]);

    return (
        <div className="learners-quiz-page-container">
            {!isQuizFinished && <div className="learners-quiz-container">
                <div className="question-section">
                    <button onClick={() => navigate("/")} className="exit-btn">
                        <span className="exit-btn-icon"></span>
                        <span className="exit-btn-label">Exit Assessment</span>
                    </button>

                    {isQuizStarted && !isQuizFinished && <p className="progress-indicator">{currentQuestionIndex + 1}/{learnersQuizData.length}</p>}
                    {isQuizStarted && !isQuizFinished && <div className="question-container">
                        <p className="question">{learnersQuizData[currentQuestionIndex] && learnersQuizData[currentQuestionIndex].question}</p>
                    </div>}
                    {!isQuizStarted && !isQuizFinished && <div className="quiz-title">Learner's Assessment Quiz</div>}
                </div>
                <div className="options-section">
                    {!isQuizStarted && !isQuizFinished && <p className="quiz-description">Not sure which courses to begin with? Take our quick learner's assessment, and we'll help you by recommending few courses for you!</p>}
                    {!isQuizStarted && !isQuizFinished && <button className="quiz-start-btn" onClick={startQuiz}>Take the Quiz</button>}
                    {isQuizStarted && !isQuizFinished && <div className="options-container">
                        {learnersQuizData[currentQuestionIndex] && learnersQuizData[currentQuestionIndex].layout === "Square" && <OptionsSquareLayout questionData={learnersQuizData[currentQuestionIndex]} updatePoints={(points) => { goToNextQuestion(points) }} />}
                        {learnersQuizData[currentQuestionIndex] && learnersQuizData[currentQuestionIndex].layout !== "Square" && <OptionsRectangularLayout questionData={learnersQuizData[currentQuestionIndex]} updatePoints={(points) => { goToNextQuestion(points) }} />}
                    </div>}
                </div>
            </div>}

            {isQuizFinished &&
                <div className="quiz-assessment-summary">
                    <div className="quiz-assessment-summary-header-container">
                        <div className="header-checkmark-container">
                            <span className="checkmark-icon"></span>
                        </div>
                        <h1 className="quiz-assessment-summary-header">Learner's Assessment Complete</h1>
                    </div>
                    <p className="quiz-assessment-summary-subheader">Thank you for taking our learner's assessment! Based on your answers, we recommend the following courses for you to get started with - </p>
                    <CourseCards courses={recommendedCourses} />
                    <button className="skip-now-action-btn" onClick={() => navigate("/")}>Skip for now</button>
                </div>
            }
        </div>
    )
}
