import React, { useEffect, useState } from 'react';
import * as apiFunction from '../../../services/apiFunctions';
import * as strapiFunction from '../../../services/strapiFunctions';
import * as url from '../../../config/environmentURL';
import { useAuth } from '../../../services/useAuth';

import './Situational.scss';

export const Situational = (props) => {

    const { user } = useAuth();

    const [guideMoods, setGuideMoods] = useState({});

    const [selectedOption, setSelectedOption] = useState("");
    const [isAnswerCorrect, setIsAnswerCorrect] = useState();

    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState();

    const confirmAnswer = (option) => {
        setSelectedOption(option);
        setIsAnswerSubmitted(true);
        if (option === props.slideData.content[0].CorrectOption) {
            setIsAnswerCorrect(true);
        }
        else {
            setIsAnswerCorrect(false);
        }
    }

    useEffect(() => {
        async function getUserGuideData() {
            let guideResponse = await apiFunction.getAPIData(url.currentURL + "guide/" + user);
            if (guideResponse.success) {
                let guideData = guideResponse.responseData.data.results.guideData;
                let guideMoodResponse = await strapiFunction.getGuideMoodData(guideData.guide);

                let guideMoodDataArray = guideMoodResponse.responseData.data.data;
                let guideMoodsObj = {};
                for (let index in guideMoodDataArray) {
                    let guideMoodData = guideMoodDataArray[index].attributes;
                    guideMoodsObj = {
                        ...guideMoodsObj,
                        [guideMoodData.Mood]: guideMoodData.MoodImage.data.attributes.url
                    }
                }
                setGuideMoods(guideMoodsObj);
            }
        }
        getUserGuideData();

    }, [user]);

    return (
        <div className="situational-container">
            <h1 className="situational-header">Let us think..</h1>
            {!isAnswerSubmitted && <p className="situation-description">{props.slideData.content[0].Situation}</p>}
            {!isAnswerSubmitted && <div className="situation-options-container">
                <div className="situation-option" onClick={() => { confirmAnswer("Option1") }}>
                    <p className="option-number">Option 1</p>
                    <p className="option-description">{props.slideData.content[0].Option1}</p>
                </div>

                <div className="situation-option" onClick={() => { confirmAnswer("Option2") }}>
                    <p className="option-number">Option 2</p>
                    <p className="option-description">{props.slideData.content[0].Option2}</p>
                </div>
            </div>}

            {isAnswerSubmitted &&
                <div className="speech-bubble-container">
                    <div className="speech-bubble">
                        <h2 className={`speech-bubble-header ${isAnswerCorrect ? "speech-bubble-header--correct" : "speech-bubble-header--incorrect"}`}>
                            That's {(isAnswerCorrect ? "Correct" : "Incorrect")}!
                        </h2>
                        <p className="speech-bubble-description">{props.slideData.content[0].Explanation}</p>
                    </div>
                    <img src={guideMoods[(isAnswerCorrect ? "Happy" : "Sad")]} alt={guideMoods[(isAnswerCorrect ? "Happy" : "Sad")]} className="speech-bubble-img"></img>
                </div>}
        </div>
    )
}