import * as url from '../config/environmentURL';
import axios from 'axios';

export const getGuideData = async () => {
    const getGuideDataURL = url.strapiURL + "guides?populate[0]=guide_moods&populate[1]=guide_moods.MoodImage&populate[2]=guide_moods.MoodImage.media";
    let response = await getStrapiData(getGuideDataURL);
    return response;
}

export const getLearnersQuizData = async () => {
    const getLearnersQuizDataURL = url.strapiURL + "learners-quizs?populate[0]=learners_quiz_options&populate[1]=learners_quiz_options.Image&populate[2]=learners_quiz_options.Image.media&sort[0]=id";
    let response = await getStrapiData(getLearnersQuizDataURL);
    return response;
}

export const getCourseData = async (courseName) => {
    const getCourseDataURL = url.strapiURL + "courses?populate[0]=learning_outcomes&populate[1]=course_lessons&populate[2]=course_lessons.course_slides&populate[3]=course_lessons.course_slides.Content&populate[4]=course_lessons.course_slides.Content.Illustration&populate[5]=course_lessons.course_slides.Content.Cards&populate[6]=course_lessons.course_slides.Content.SpeechBubble&populate[7]=conclusion&&populate[8]=conclusion.games.Illustration&&populate[9]=Illustration&&sort[0]=course_lessons.course_slides.Number&pagination[page]=1&pagination[pageSize]=1&filters[Name][$eq]=" + courseName;
    let response = await getStrapiData(getCourseDataURL);
    return response;
}

export const getGuideMoodData = async (guide) => {
    const getGuideMoodDataURL = url.strapiURL + "guide-moods?populate[0]=MoodImage&filters[Name][$eq]=" + guide;
    let response = await getStrapiData(getGuideMoodDataURL);
    return response;

}

export const getCoursesByCategoryData = async () => {
    const getCoursesByCategoryDataURL = url.strapiURL + "course-categories?populate[0]=courses&populate[1]=courses.Illustration&populate[2]=courses.course_lessons&sort[0]=id";
    let response = await getStrapiData(getCoursesByCategoryDataURL);
    return response;
}

export const getCourseOverviewData = async (courseName) => {
    const getCourseOverviewDataURL = url.strapiURL + "courses?populate[0]=resources&populate[1]=Illustration&populate[2]=course_lessons&populate[3]=course_lessons.course_slides&filters[Name][$eq]=" + courseName;
    let response = await getStrapiData(getCourseOverviewDataURL);
    return response;
}

export const getGamesData = async () => {
    const getGamesDataURL = url.strapiURL + "games?populate[0]=Illustration";
    let response = await getStrapiData(getGamesDataURL);
    return response;
}

export const getTwineConsentGameData = async () => {
    const getTwineConsentGameDataURL = url.strapiURL + "games?populate[0]=Configuration.File&filters[Name][$eq]=Twine Consent Game";
    let response = await getStrapiData(getTwineConsentGameDataURL);
    return response;
}

export const getLandingPageData = async () => {
    const getLandingPageDataURL = url.strapiURL + "landing-page?populate[0]=Introduction&populate[1]=Introduction.Illustration&populate[2]=Explore&populate[3]=Explore.Illustration&populate[4]=Explore.GameIllustration&populate[5]=Explore.recommended_courses&populate[6]=Info&populate[7]=Info.InfoCards&populate[8]=Info.InfoCards.Illustration&&populate[9]=Explore.recommended_courses.Illustration&populate[10]=Explore.TargetIllustration";
    let response = await getStrapiData(getLandingPageDataURL);
    return response;
}

export const getPathwaysData = async () => {
    const getPathwaysDataURL = url.strapiURL + "pathways?populate[0]=Illustration&populate[1]=courses";
    let response = await getStrapiData(getPathwaysDataURL);
    return response;
}

export const getGameOverviewData = async (gameName) => {
    const getGameOverviewDataURL = url.strapiURL + "games?populate[0]=Illustration&filters[Name][$eq]=" + gameName;
    let response = await getStrapiData(getGameOverviewDataURL);
    return response;
}

export const getGameData = async (gameName) => {
    const getGameDataURL = url.strapiURL + "games?populate[0]=Illustration&populate[1]=Configuration&populate[2]=Configuration.File&filters[Name][$eq]=" + gameName;
    let response = await getStrapiData(getGameDataURL);
    return response;
}

export const getAboutUsPageData = async () => {
    const getAboutUsPageDataURL = url.strapiURL + "about-us?populate[0]=BannerImage&populate[1]=AboutUsCards&populate[2]=AboutUsCards.ProfileImage";
    let response = await getStrapiData(getAboutUsPageDataURL);
    return response;
}

export const getAllCourses = async () => {
    const getAllCoursesURL = url.strapiURL + "courses?populate[0]=Illustration&populate[1]=course_lessons";
    let response = await getStrapiData(getAllCoursesURL);
    return response;
}

export const getBadgeData = async () => {
    const getBadgeDataURL = url.strapiURL + "badges?populate[0]=badge_levels&populate[1]=badge_levels.BadgeImage";
    let response = await getStrapiData(getBadgeDataURL);
    return response;
}

export const getLoginPageData = async () => {
    const getLoginPageDataURL = url.strapiURL + "login?populate[0]=Illustration";
    let response = await getStrapiData(getLoginPageDataURL);
    return response;
}

export const getSignupPageData = async () => {
    const getSignupPageData = url.strapiURL + "signup?populate[0]=Illustration";
    let response = await getStrapiData(getSignupPageData);
    return response;
}

const getStrapiData = async (endpoint) => {
    try {
        const responseData = await axios.get(endpoint);
        return {
            "success": true,
            "responseData": responseData,
            "errorData": {}
        };
    } catch (error) {
        return {
            "success": false,
            "responseData": {},
            "errorData": error
        };
    }
}