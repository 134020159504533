import React, { useEffect, useState } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { NavigationBar } from '../../components/navigation-bar/NavigationBar';
import './NavbarLayout.scss';


export const NavbarLayout = () => {
    const outlet = useOutlet();
    const location = useLocation();

    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setIsCollapsed(true);
    }, [location]);

    const toggleNavBar = (collapsed) => {
        setIsCollapsed(collapsed);
    }

    return (
        <div className="navbar-layout-container">
            <NavigationBar toggleNavBar={toggleNavBar} />
            <div className={`outlet-container ${isCollapsed ? "expanded" : ""}`}>
                {outlet}
            </div>
        </div>
    );
}