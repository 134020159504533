import React, { useCallback, useEffect, useRef, useState } from 'react';
import Crossword from "@jaredreisinger/react-crossword";
import './CrosswordGame.scss';

export const CrosswordGame = (props) => {

    const [data, setData] = useState();
    const crossword = useRef();

    const fillAllAnswers = useCallback((event) => {
        crossword.current.fillAllAnswers();
    }, []);

    const reset = useCallback((event) => {
        if (crossword.current) {
            crossword.current.reset();
        }
    }, []);

    useEffect(() => {
        reset();
        let crossWordData = props.configuration[0].Data;
        setData(crossWordData);
    }, [props, reset]);

    if (crossword.isCorrect) {
    } else {
        // console.log(crossword.isCorrect);
        // console.log("incorrect");
    }

    return (
        <div className="crossword-container">
            <div className="crossword-btn-container">
                <button onClick={fillAllAnswers} className="show-btn">
                    <span className="show-btn-icon"></span>
                    <span className="show-btn-label">Show Answers</span>
                </button>
                <button onClick={reset} className="reset-btn">
                    <span className="reset-btn-icon"></span>
                    <span className="reset-btn-label">Reset</span>
                </button>
            </div>
            {data && <Crossword
                data={data}
                ref={crossword}
                theme={{
                    gridBackground: "#441151",
                    cellBackground: "#F9EEFC",
                    cellBorder: "#441151",
                    textColor: "rgb(0,0,0)",
                    numberColor: "rgba(0,0,0, 0.5)",
                    focusBackground: "#FFBA08",
                    highlightBackground: "#F9EEFC"
                }}
            />}
        </div>
    )
}