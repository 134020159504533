import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as apiFunctions from '../../services/apiFunctions';
import * as strapiFunctions from '../../services/strapiFunctions';
import * as courseNames from '../../constants/courseNames';
import { useAuth } from '../../services/useAuth';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import './CoursePage.scss';
import * as url from '../../config/environmentURL';
import { LearningOutcomes } from '../../components/learning-outcomes/LearningOutcomes';
import { Layout1 } from '../../components/course-layouts/layout-1/Layout1';
import { Layout2 } from '../../components/course-layouts/layout-2/Layout2';
import { Layout3 } from '../../components/course-layouts/layout-3/Layout3';
import { Layout4 } from '../../components/course-layouts/layout-4/Layout4';
import { TitleSlide } from '../../components/course-layouts/title-slide/TitleSlide';
import { TrueOrFalse } from '../../components/course-layouts/true-or-false/TrueOrFalse';
import { CourseNavigation } from '../../components/course-navigation/CourseNavigation';
import { Conclusion } from '../../components/conclusion/Conclusion';
import { Situational } from '../../components/course-layouts/situational/Situational';

export const CoursePage = () => {

    const { id } = useParams();
    const { user } = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();

    const [learningOutcomes, setLearningOutcomes] = useState([]);
    const [isOnLearningOutcomes, setIsOnLearningOutcomes] = useState(false);

    const [isOnCourse, setIsOnCourse] = useState(false);

    const [lessonIndex, setLessonIndex] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);
    const [lessonSlides, setLessonSlides] = useState([]);
    const [courseImage, setCourseImage] = useState({});
    const [courseProgressData, setCourseProgressData] = useState({});

    const [isOnTitle, setIsOnTitle] = useState(false);
    const [title, setTitle] = useState('');

    const [conclusion, setConclusion] = useState({});
    const [isOnConclusion, setIsOnConclusion] = useState(false);

    const [isCourseNavigationExpanded, setIsCourseNavigationExpanded] = useState(false);

    const courseContent = useRef(null);

    useEffect(() => {
        async function getCourseData() {
            let contentResponse = await strapiFunctions.getCourseData(courseNames.courseNames[id]);
            let contentResponseData = contentResponse.responseData.data.data[0].attributes;

            let courseImageData = contentResponseData["Illustration"].data.attributes;
            setCourseImage(courseImageData);

            let learningOutcomesData = contentResponseData["learning_outcomes"].data;
            let learningOutcomesArray = [];
            for (let index in learningOutcomesData) {
                let learningOutcomeData = learningOutcomesData[index].attributes;
                let learningOutcome = {
                    outcome: learningOutcomeData["Outcome"],
                    type: learningOutcomeData["Type"]
                }
                learningOutcomesArray.push(learningOutcome);
            }
            setLearningOutcomes(learningOutcomesArray);

            let conclusionData = contentResponseData["conclusion"].data.attributes;
            let conclusionObj = {};
            conclusionObj["conclusionText"] = conclusionData["ConclusionText"];

            let gamesData = conclusionData["games"].data;
            let gamesDataArray = [];

            for (let index in gamesData) {
                let gameData = gamesData[index].attributes;
                let gameDataObj = {};
                gameDataObj["name"] = gameData["Name"];
                gameDataObj["description"] = gameData["Description"];
                gameDataObj["illustration"] = gameData["Illustration"].data.attributes;

                gamesDataArray.push(gameDataObj);
            }

            conclusionObj["games"] = gamesDataArray;

            setConclusion(conclusionObj);

            let courseLessonsData = contentResponseData["course_lessons"].data;
            let courseLessonArray = [];
            for (let index in courseLessonsData) {
                let courseLessonData = courseLessonsData[index].attributes;

                let lessonSlidesData = courseLessonData["course_slides"].data;
                let lessonSlidesArray = [];
                for (let index in lessonSlidesData) {
                    let lessonSlideData = lessonSlidesData[index].attributes;
                    let lessonSlide = {
                        layout: lessonSlideData["Layout"],
                        name: lessonSlideData["Name"],
                        content: lessonSlideData["Content"],
                        number: lessonSlideData["Number"]
                    }
                    lessonSlidesArray.push(lessonSlide);
                }

                lessonSlidesArray.sort((slideA, slideB) => { return slideA.number - slideB.number });

                let courseLesson = {
                    name: courseLessonData["Name"],
                    number: courseLessonData["Number"],
                    slides: lessonSlidesArray
                }

                courseLessonArray.push(courseLesson);
            }

            courseLessonArray.sort((courseA, courseB) => { return courseA.number - courseB.number });

            setLessonSlides(courseLessonArray);
        }

        async function getCourseProgressData() {
            let courseProgressResponse = await apiFunctions.getAPIData(url.currentURL + `course-progress?emailID=${user}&courseName=${courseNames.courseNames[id]}`);
            let courseProgressDetails = courseProgressResponse.responseData.data.results.courseData;
            setCourseProgressData(courseProgressDetails);
        }

        getCourseData();
        getCourseProgressData();

    }, [id, user]);

    useEffect(() => {
        let paramsArray = [];
        searchParams.forEach((value, key) => {
            paramsArray.push({
                key: key,
                value: value
            });
        });
        if (paramsArray.length === 0) {
            setSearchParams({ learningOutcome: true });
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        let learningOutcomeParam = searchParams.get("learningOutcome");

        if (learningOutcomeParam && learningOutcomeParam === "true") {
            setIsOnLearningOutcomes(true);
            setIsOnCourse(false);
            setIsOnConclusion(false);
        }

        let lessonParam = searchParams.get("lesson");
        let slideParam = searchParams.get("slide");

        if (lessonParam && slideParam && lessonSlides.length > 0) {
            let lessonIndexValue = Number(lessonParam);
            let slideIndexValue = Number(slideParam);

            lessonIndexValue = ((isNaN(lessonIndexValue) || lessonIndexValue >= lessonSlides.length) ? 0 : lessonIndexValue);
            slideIndexValue = ((isNaN(slideIndexValue) || slideIndexValue >= lessonSlides[lessonIndexValue].slides.length) ? 0 : slideIndexValue);

            setLessonIndex(lessonIndexValue);
            setSlideIndex(slideIndexValue);

            setSearchParams({ lesson: lessonIndexValue, slide: slideIndexValue });

            setIsOnCourse(true);
            setIsOnLearningOutcomes(false);
            setIsOnConclusion(false);
            setIsOnTitle(false);
        }

        let courseFinishedParam = searchParams.get("courseFinished");

        if (courseFinishedParam && courseFinishedParam === "true") {
            setIsOnConclusion(true);
            setIsOnCourse(false);
            setIsOnLearningOutcomes(false);
        }

    }, [lessonSlides, searchParams, setSearchParams]);

    const startCourse = () => {
        setSearchParams({ lesson: 0, slide: 0 });
        setIsOnLearningOutcomes(false);
        setIsOnCourse(true);
    }

    useEffect(() => {
        async function updateCourseProgressData() {
            let courseProgressDataObj = {
                "emailID": user,
                "courseName": courseNames.courseNames[id],
                "slidesProgress": courseProgressData
            };

            let formData = new FormData();
            formData.append("courseProgressData", JSON.stringify(courseProgressDataObj));

            await apiFunctions.postAPIData(url.currentURL + "course-progress", formData);
        }

        if (Object.keys(courseProgressData).length > 0) {
            updateCourseProgressData();
        }
    }, [courseProgressData, id, user])

    const updateCourseProgress = useCallback(
        (slideIndex, lessonIndex) => {
            setCourseProgressData(existingData => {
                const lessonSlideProgress = existingData[lessonSlides[lessonIndex].name];
                return {
                    ...existingData,
                    [lessonSlides[lessonIndex].name]: {
                        ...lessonSlideProgress,
                        [lessonSlides[lessonIndex].slides[slideIndex].name]: true
                    }
                }
            });
        },
        [lessonSlides]
    );

    const nextSlide = () => {
        updateCourseProgress(slideIndex, lessonIndex);
        if (slideIndex < lessonSlides[lessonIndex].slides.length - 1) {
            setSlideIndex(slideIndex + 1);
            setSearchParams({ lesson: lessonIndex, slide: slideIndex + 1 });
        }
        else {
            if (lessonIndex < lessonSlides.length - 1) {
                setTitle(lessonSlides[lessonIndex + 1].name);
                setIsOnTitle(true);
            }
            else {
                setIsOnConclusion(true);
                setIsOnCourse(false);
                setSearchParams({ courseFinished: true });
            }
        }
    }

    const nextLesson = () => {
        setSlideIndex(0);
        if (lessonIndex < lessonSlides.length - 1) {
            setLessonIndex(lessonIndex + 1);
            setSearchParams({ lesson: lessonIndex + 1, slide: 0 });
        }
        else {
            setIsOnConclusion(true);
            setIsOnCourse(false);
        }
        setIsOnTitle(false);
    }

    const previousSlide = () => {
        updateCourseProgress(slideIndex, lessonIndex);
        if (slideIndex > 0) {
            setSlideIndex(slideIndex - 1);
            setSearchParams({ lesson: lessonIndex, slide: slideIndex - 1 });
        }
        else {
            if (lessonIndex > 0) {
                setIsOnTitle(true);
                setLessonIndex(lessonIndex - 1);
                setSlideIndex(lessonSlides[lessonIndex - 1].slides.length - 1);
                setSearchParams({ lesson: lessonIndex - 1, slide: lessonSlides[lessonIndex - 1].slides.length - 1 });
            }
            else {
                setIsOnLearningOutcomes(true);
                setIsOnCourse(false);
                setSearchParams({ learningOutcome: true });
            }
        }
    }

    const previousLesson = () => {
        setIsOnTitle(false);
    }

    const goToNext = () => {
        if (!isOnTitle && !isOnConclusion && !isOnLearningOutcomes) {
            courseContent.current.scrollIntoView();
        }
        if (isOnTitle) {
            nextLesson();
        }
        else if (!isOnLearningOutcomes && !isOnConclusion) {
            nextSlide();
        }
    }

    const goToPrevious = () => {
        if (!isOnTitle && !isOnConclusion && !isOnLearningOutcomes) {
            courseContent.current.scrollIntoView();
        }
        if (isOnTitle) {
            previousLesson();
        }
        else if (!isOnLearningOutcomes && !isOnConclusion) {
            previousSlide();
        }
    }

    const toggleCourseNavigation = () => {
        setIsCourseNavigationExpanded(!isCourseNavigationExpanded);
    }

    return (
        <div className="course-page-container">
            <div className="course-navigation-menu">
                <button className="course-navigation-menu-btn" onClick={toggleCourseNavigation}>
                    <span className="course-navigation-menu-btn-icon"></span>
                </button>
            </div>
            <div className={`course-navigation-container ${isCourseNavigationExpanded ? "course-navigation-container--expanded" : ""}`}>
                <CourseNavigation lessonSlides={lessonSlides} lessonIndex={lessonIndex} slideIndex={slideIndex} isOnCourse={isOnCourse} courseImage={courseImage} courseProgressData={courseProgressData} toggleCourseNavigation={toggleCourseNavigation} />
            </div>
            <div className="course-content-container">
                <div className="course-layouts">
                    {isOnLearningOutcomes && <LearningOutcomes learningOutcomes={learningOutcomes} startCourse={startCourse} />}

                    {isOnCourse && !isOnTitle && lessonSlides.length > 0 && lessonSlides[lessonIndex].slides && lessonSlides[lessonIndex].slides.length > 0 &&
                        <div className={`course-layout ${!isOnLearningOutcomes && !isOnConclusion ? "course-layout-navigation-bar" : ""}`} ref={courseContent}>
                            {lessonSlides[lessonIndex].slides[slideIndex].layout === "Layout-1" && <Layout1 slideData={lessonSlides[lessonIndex].slides[slideIndex]} nextSlide={nextSlide} previousSlide={previousSlide} />}
                            {lessonSlides[lessonIndex].slides[slideIndex].layout === "Layout-2" && <Layout2 slideData={lessonSlides[lessonIndex].slides[slideIndex]} nextSlide={nextSlide} previousSlide={previousSlide} />}
                            {lessonSlides[lessonIndex].slides[slideIndex].layout === "Layout-3" && <Layout3 slideData={lessonSlides[lessonIndex].slides[slideIndex]} nextSlide={nextSlide} previousSlide={previousSlide} />}
                            {lessonSlides[lessonIndex].slides[slideIndex].layout === "Layout-4" && <Layout4 slideData={lessonSlides[lessonIndex].slides[slideIndex]} nextSlide={nextSlide} previousSlide={previousSlide} />}
                            {lessonSlides[lessonIndex].slides[slideIndex].layout === "True-Or-False" && <TrueOrFalse slideData={lessonSlides[lessonIndex].slides[slideIndex]} nextSlide={nextSlide} previousSlide={previousSlide} />}
                            {lessonSlides[lessonIndex].slides[slideIndex].layout === "Situational" && <Situational slideData={lessonSlides[lessonIndex].slides[slideIndex]} nextSlide={nextSlide} previousSlide={previousSlide} />}
                        </div>}

                    {isOnTitle &&
                        <TitleSlide title={title} nextLesson={nextLesson} previousLesson={previousLesson} />
                    }

                    {isOnConclusion && <Conclusion conclusion={conclusion} />}
                </div>
                {!isOnLearningOutcomes && !isOnConclusion &&
                    <div className="navigation-btn-container">
                        <button onClick={goToPrevious} className="nav-slide-btn nav-slide-btn--previous">Previous</button>
                        <button onClick={goToNext} className="nav-slide-btn nav-slide-btn--next">Next</button>
                    </div>
                }
            </div>
        </div >
    )
}