import React, { useEffect, useState, useCallback } from 'react';
import './OptionsRectangularLayout.scss';

export const OptionsRectangularLayout = (props) => {

    const [selectedPoints, setSelectedPoints] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const updateSelectedPoints = useCallback(
        (points, name) => {
            if (selectedOptions.indexOf(name) !== -1) {
                if (props.questionData.multiselect) {
                    setSelectedPoints(selectedPoints - points);
                    setSelectedOptions(selectedOptions.filter(option => option !== name));
                }
                else {
                    setSelectedPoints(0);
                    setSelectedOptions([]);
                }
            }
            else {
                if (props.questionData.multiselect) {
                    setSelectedPoints(selectedPoints + points);
                    setSelectedOptions([...selectedOptions, name]);
                }
                else {
                    setSelectedPoints(points);
                    setSelectedOptions([name]);
                }
            }
        },
        [props, selectedPoints, selectedOptions]
    );

    useEffect(() => {
    }, [selectedOptions, selectedPoints]);

    useEffect(() => {
        setSelectedPoints(0);
    }, [props]);

    const goToNextQuestion = () => {
        props.updatePoints(selectedPoints);
    }

    return (
        <div className="options-component-container">
            { props.questionData.multiselect && <p className="option-selection-message">Select all that apply</p>}
            { !props.questionData.multiselect && <p className="option-selection-message">Select one</p>}
            <div className="options-rectangular-layout">
                {props.questionData.options.map((option) =>
                    <div className={`option-container option-container--${props.questionData.optionSize.toLowerCase()} ${selectedOptions.indexOf(option.name) !== -1 ? "option-container-selected" : ""}`} key={option.name} onClick={() => { updateSelectedPoints(option.points, option.name) }}>
                        <p className="option-name">{option.name}</p>
                    </div>)}
            </div>
            <button onClick={goToNextQuestion} className="next-question-btn">Next</button>
        </div>
    )
}