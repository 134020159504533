import React, { useEffect, useState } from 'react';
import './CourseNavigation.scss';
import * as courseNames from '../../constants/courseNames';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const CourseNavigation = (props) => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [lessonSlides, setLessonSlides] = useState([]);
    const [courseImage, setCourseImage] = useState({});
    const [courseProgressData, setCourseProgressData] = useState({});

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        let lessonSlidesArray = [];
        for (let index in props.lessonSlides) {
            let lesson = props.lessonSlides[index];

            if (String(props.lessonIndex) === index && props.isOnCourse) {
                lesson["expanded"] = true;
            }
            else {
                lesson["expanded"] = false;
            }
            lessonSlidesArray.push(lesson);
        }
        setLessonSlides(lessonSlidesArray);

        setCourseImage(props.courseImage);

        setCourseProgressData(props.courseProgressData);

    }, [props]);

    const toggleExpand = (lessonName) => {
        setLessonSlides(existingLessons => {
            let lessonItemIndex = existingLessons.findIndex(lesson => lesson.name === lessonName);
            return [
                ...existingLessons.slice(0, lessonItemIndex),
                {
                    ...existingLessons[lessonItemIndex],
                    expanded: !existingLessons[lessonItemIndex].expanded
                },
                ...existingLessons.slice(lessonItemIndex + 1)
            ]
        });
    }

    const goToSlide = (slideName) => {
        let lessonIndex, slideIndex;
        for (let index in lessonSlides) {

            let lesson = lessonSlides[index];
            let indexOfSlide = lesson.slides.findIndex((slide) => { return slide.name === slideName });

            if (indexOfSlide !== -1) {
                slideIndex = indexOfSlide;
                lessonIndex = index;

                break;
            }
        }
        setSearchParams({ lesson: lessonIndex, slide: slideIndex });
        toggleCourseNavigation();
    }

    const goToLearningOutcomes = () => {
        setSearchParams({ learningOutcome: true });
        toggleCourseNavigation();
    }

    const goToConclusion = () => {
        setSearchParams({ courseFinished: true });
        toggleCourseNavigation();
    }

    const goToCourseOverview = () => {
        navigate("/course-overview/" + id);
    }

    useEffect(() => {
        // console.log(lessonSlides);
    }, [lessonSlides]);

    const toggleCourseNavigation = () => {
        props.toggleCourseNavigation();
    }

    return (
        <div className="navigation-container">
            <div className="exit-btn-container">
                <button className="exit-btn" onClick={goToCourseOverview}>
                    <span className="exit-btn-icon"></span>
                    <span className="exit-btn-label">Save and Exit</span>
                </button>
                <button className="close-btn" onClick={toggleCourseNavigation}>
                    <span className="close-btn-icon"></span>
                </button>
            </div>
            <div className="navigation-course-header-container">
                <img src={courseImage.url} alt={courseImage.alternativeText} className="navigation-course-header-illustration"></img>
                <h1 className="navigation-course-header">{courseNames.courseNames[id]}</h1>
            </div>

            <div className="learning-outcomes-navigation-container" onClick={() => { goToLearningOutcomes() }}>
                <p className="learning-outcomes-name">Learning Outcomes</p>
            </div>

            <div className="lessons-navigation-container">
                {lessonSlides.length > 0 && lessonSlides.map((lesson) =>
                    <div className="lesson-container" key={lesson.name} onClick={() => { toggleExpand(lesson.name) }}>
                        <div className="lesson-detail-container">
                            {courseProgressData[lesson.name] && <div className={`progress-indicator-container ${((Object.values(courseProgressData[lesson.name])).filter((value) => { return value === true }).length === Object.keys(courseProgressData[lesson.name]).length) ? "progress-indicator-container--completed" : ""}`}>
                                <span className="progress-checkmark-icon"></span>
                            </div>}
                            <p className="lesson-name">{lesson.name}</p>
                            <div className="lesson-control-container">
                                {courseProgressData[lesson.name] && <span className="lesson-progress-indicator">{(Object.values(courseProgressData[lesson.name])).filter((value) => { return value === true }).length}/{lesson.slides.length}</span>}
                                <button className={`lesson-expand-collapse-btn ${lesson.expanded ? "collapse-btn" : "expand-btn"}`}></button>
                            </div>
                        </div>
                        <div className={`slides-container ${lesson.expanded ? "slides-container--expanded" : ""}`}>
                            {lesson.slides.length > 0 && lesson.slides.map((slide) =>
                                <div className="slide-container" key={slide.name} onClick={() => { goToSlide(slide.name) }}>
                                    {courseProgressData[lesson.name] && <div className={`progress-indicator-container ${courseProgressData[lesson.name][slide.name] ? "progress-indicator-container--completed" : ""}`}>
                                        <span className="progress-checkmark-icon"></span>
                                    </div>}
                                    <p className="slide-name">{slide.name.split("-")[0]}</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <div className="conclusion-navigation-container" onClick={() => { goToConclusion() }}>
                <p className="conclusion-name">Conclusion</p>
            </div>
        </div>
    )
}