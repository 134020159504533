import React from 'react';
import { Section1 } from '../../course-sections/section-1/Section1';
import './Layout2.scss';

export const Layout2 = (props) => {

    return (
        <div className="layout2-container">
            <Section1 slideContent={props.slideData.content[0]} />
        </div>
    )
}