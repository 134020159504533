import React, { useEffect, useState } from 'react';
import * as apiFunction from '../../../services/apiFunctions';
import * as strapiFunction from '../../../services/strapiFunctions';
import * as url from '../../../config/environmentURL';
import { useAuth } from '../../../services/useAuth';
import './TrueOrFalse.scss';

export const TrueOrFalse = (props) => {

    const { user } = useAuth();

    const [guideMoods, setGuideMoods] = useState({});

    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [isAnswerCorrect, setIsAnswerCorrect] = useState();

    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);

    useEffect(() => {
        async function getUserGuideData() {
            let guideResponse = await apiFunction.getAPIData(url.currentURL + "guide/" + user);
            if (guideResponse.success) {
                let guideData = guideResponse.responseData.data.results.guideData;
                let guideMoodResponse = await strapiFunction.getGuideMoodData(guideData.guide);

                let guideMoodDataArray = guideMoodResponse.responseData.data.data;
                let guideMoodsObj = {};
                for (let index in guideMoodDataArray) {
                    let guideMoodData = guideMoodDataArray[index].attributes;
                    guideMoodsObj = {
                        ...guideMoodsObj,
                        [guideMoodData.Mood]: guideMoodData.MoodImage.data.attributes.url
                    }
                }
                setGuideMoods(guideMoodsObj);
            }
        }
        getUserGuideData();

    }, [user]);

    const confirmAnswer = (answer) => {
        setSelectedAnswer(answer);
        setIsAnswerSubmitted(true);
        if (answer === String(props.slideData.content[0].Answer)) {
            setIsAnswerCorrect(true);
        }
        else {
            setIsAnswerCorrect(false);
        }
    }

    return (
        <div className="true-or-false-container">
            <h1 className="true-or-false-header">True or False?</h1>
            <p className="true-or-false-statement">{props.slideData.content[0].Statement}</p>
            <div className="true-or-false-btn-container">
                <button className={`true-or-false-btn ${selectedAnswer === "true" ? (isAnswerCorrect ? "correct-btn" : "incorrect-btn") : ""}`} onClick={() => { confirmAnswer("true") }} disabled={isAnswerSubmitted}>True</button>
                <button className={`true-or-false-btn ${selectedAnswer === "false" ? (isAnswerCorrect ? "correct-btn" : "incorrect-btn") : ""}`} onClick={() => { confirmAnswer("false") }} disabled={isAnswerSubmitted}>False</button>
            </div>

            {isAnswerSubmitted &&
                <div className="speech-bubble-container">
                    <div className="speech-bubble">
                        <h2 className={`speech-bubble-header ${isAnswerCorrect ? "speech-bubble-header--correct" : "speech-bubble-header--incorrect"}`}>
                            That's {(isAnswerCorrect ? "Correct" : "Incorrect")}!
                        </h2>
                        <p className="speech-bubble-description">{props.slideData.content[0].Explanation}</p>
                    </div>
                    <img src={guideMoods[(isAnswerCorrect ? "Happy" : "Sad")]} alt={guideMoods[(isAnswerCorrect ? "Happy" : "Sad")]} className="speech-bubble-img"></img>
                </div>}
        </div >
    )
}