import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../services/useAuth';
import { useNavigate } from "react-router-dom";
import "./LoginPage.scss";
import helperOwlImg from '../../assets/illustrations/helper-owl.svg';
import { Toast } from '../../components/toast/Toast';
import * as toastStates from '../../constants/toastStates';
import * as utilityFunctions from '../../services/utilityFunctions';
import * as strapiFunctions from '../../services/strapiFunctions';
import logoImg from '../../assets/logo/icon-logo.svg';

export const LoginPage = () => {

    const { login } = useAuth();
    const navigate = useNavigate();

    const [illustration, setIllustration] = useState();

    // State variable to store Form Values
    const [formValues, setFormValues] = useState({
        emailID: '',
        password: ''
    });

    // State variable to store valid/invalid state of Form values
    const [isFormValueValid, setIsFormValueValid] = useState({
        emailID: true,
        password: true,
    });

    // State variable which determines if toast is displayed
    const [isToastDisplayed, setIsToastDisplayed] = useState(false);

    // State variables to store the state, main message and sub message of toast
    const [toastState, setToastState] = useState("");
    const [toastMainMessage, setToastMainMessage] = useState("");
    const [toastSubMessage, setToastSubMessage] = useState("");

    //State variable which determines if submit was attempted
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        async function getLoginPageDetails() {
            let response = await strapiFunctions.getLoginPageData();
            if (response.success) {
                let responseData = response.responseData.data.data.attributes;
                let illustrationData = responseData["Illustration"].data.attributes;
                setIllustration(illustrationData);
            }
        }

        getLoginPageDetails();
    }, []);

    // Function to validate input and set isSubmitted when user attempts submit
    const handleSubmit = useCallback(
        async (event) => {
            setIsSubmitted(false);
            event.preventDefault();

            for (const key in formValues) {
                validateInput(key, formValues[key]);
            }

            setIsSubmitted(true);
        },
        [formValues]
    );

    // Function that determines if all Form values are valid
    const isAllFormValuesValid = useCallback(
        () => {
            for (const key in isFormValueValid) {
                if (!isFormValueValid[key]) {
                    return false;
                }
            }
            return true;
        },
        [isFormValueValid]
    );

    // Function that submits the form by calling the endpoint and handling response
    const submitForm = useCallback(
        async () => {
            const responseData = await login(formValues);

            if (!responseData.success) {

                utilityFunctions.scrollToTop();
                setIsToastDisplayed(true);

                if (responseData.errorData.response.status === 404) {
                    setToastState(toastStates.warningState);
                    setToastMainMessage("Uh oh! The user does not exist!");
                    setToastSubMessage("Please try signing up first and try again");
                }

                else if (responseData.errorData.response.status === 401) {
                    setToastState(toastStates.errorState);
                    setToastMainMessage("Oh no! That didn't work out!");
                    setToastSubMessage("Please check the credentials & try again");
                }

                else {
                    setToastState(toastStates.errorState);
                    setToastMainMessage("Uh oh! Something went wrong!");
                    setToastSubMessage("Please try again in some time");
                }
            }
            else {
                if (responseData.responseData.data.results.user.isFirstTimeUser) {
                    navigate("/choose-your-guide?isFirstTimeUser=true");
                }
                else {
                    navigate("/", { replace: true });
                }
            }
        },
        [formValues, login, navigate]
    );

    // useEffect to trigger submitForm when input is validated
    useEffect(() => {
        if (isSubmitted && isAllFormValuesValid()) {
            submitForm();
        }
    }, [isSubmitted, isAllFormValuesValid, submitForm]);

    // Function to validate a Form value
    const validateInput = (inputFieldName, inputFieldValue) => {

        setIsFormValueValid((values) => ({
            ...values,
            [inputFieldName]: inputFieldValue === "" ? false : true,
        }));

        if (inputFieldName === "emailID" && inputFieldValue !== "") {
            setIsFormValueValid((values) => ({
                ...values,
                'emailID': (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputFieldValue)) ? true : false,
            }));
        }
    }

    // Function to persist Form values on change
    const handleInputChange = (event) => {
        let inputFieldName = event.target.name;
        let inputFieldValue = event.target.value;

        event.persist();

        validateInput(inputFieldName, inputFieldValue);

        setFormValues((values) => ({
            ...values,
            [inputFieldName]: inputFieldValue,
        }));
    }

    // Function that removes/dismisses toast
    const removeToast = () => {
        setIsToastDisplayed(false);
    }

    return (
        <div className="login-form-page-container">
            <div className="logo-container" onClick={() => { navigate("/") }}>
                <img src={logoImg} alt="logo-icon" className="logo-img"></img>
            </div>
            <div className="toast-container">
                {isToastDisplayed && <Toast toastState={toastState} toastMainMessage={toastMainMessage} toastSubMessage={toastSubMessage} removeToast={removeToast} />}
            </div>
            <div className="login-form-section">
                <div className="form-section">
                    <div className="helper-illustration-container">
                        <div className="illustration-outer-circle">
                            <div className="illustration-inner-circle">
                                <img src={helperOwlImg} className="illustration-img" alt="Cute helper who guides you through the website"></img>
                            </div>
                        </div>
                    </div>
                    <div className="header-container">
                        <h1 className="header-title">Welcome Back!</h1>
                        <p className="header-subtitle">It’s great to see you! Enter your credentials to login</p>
                    </div>
                    <form onChange={() => setIsSubmitted(false)} onSubmit={handleSubmit} className="form-container">
                        <div className="form-input-container">
                            <label htmlFor="emailID" className="form-input-label">Email</label>
                            <input id="emailID" type="text" name="emailID" aria-label="Enter email id" value={formValues.emailID} onChange={handleInputChange} className="form-input"></input>
                            {!isFormValueValid.emailID && <p className="form-input-validation-msg">The email entered is invalid</p>}
                        </div>

                        <div className="form-input-container">
                            <label htmlFor="password" className="form-input-label">Password</label>
                            <input id="password" type="password" name="password" aria-label="Enter your password" value={formValues.password} onChange={handleInputChange} className="form-input"></input>
                            {!isFormValueValid.password && <p className="form-input-validation-msg">Please enter a password</p>}
                        </div>

                        <div className="form-input-container">
                            <button type="submit" aria-label="Log In" className="form-submit-btn">Log In</button>
                        </div>
                    </form>
                </div>
                <div className="signup-link-section">
                    <p className="signup-link-message">Don't have an account? <a href="/signup" aria-label="Sign Up for an account" className="signup-link">Sign up</a></p>
                </div>
            </div>
            <div className="illustration-section">
                {illustration && <img src={illustration.url} alt={illustration.alternativeText} className="illustration-img"></img>}
            </div>
        </div>
    );
}