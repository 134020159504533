import React, { useState, useEffect } from 'react';
import * as apiFunctions from '../../services/apiFunctions';
import { useAuth } from '../../services/useAuth';
import { useNavigate } from "react-router-dom";
import './ProfilePage.scss';
import * as url from '../../config/environmentURL';
import userAvatar from '../../assets/illustrations/user-avatar.svg';

export const ProfilePage = () => {

    const { user, logout } = useAuth();
    const navigate = useNavigate();

    // State variable to store user details
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        emailID: ''
    });

    // useEffect to get user details
    useEffect(() => {
        async function getUserDetails() {
            let response = await apiFunctions.getAPIData(url.currentURL + "user/" + user);
            if (response.success) {
                let responseData = response.responseData.data.results.userData;
                setUserDetails(responseData);
            }
            else {
                if (response.errorData.response.status === 401) {
                    logout();
                }
            }
        }
        getUserDetails();
    }, []);

    const navigateToSubPage = (path) => {
        navigate(path);
    }

    return (
        <div className="profile-page-container">
            <div className="profile-section">
                <div className="profile-banner-container">
                </div>
                <div className="profile-details-container">
                    <div className="profile-img-container">
                        {/* {userDetails.firstName && userDetails.lastName && <span className="profile-img-text">{userDetails.firstName[0]}{userDetails.lastName[0]}</span>} */}
                        <img src={userAvatar} alt="user-avatar" className="profile-img"></img>
                    </div>
                    <div className="profile-details">
                        <h1 className="profile-header">Welcome to your profile</h1>
                        {userDetails.firstName && userDetails.lastName && <p className="profile-username">{userDetails.firstName} {userDetails.lastName}</p>}
                    </div>
                </div>
                <div className="profile-tiles-section">
                    <div className="profile-tiles-container">
                        <div className="profile-tile-container" onClick={() => { navigateToSubPage('personal-details') }}>
                            <div className="profile-tile-overlay">
                            </div>
                            <div className="profile-tile-icon-container">
                                <div className="profile-tile-icon personal-details"></div>
                            </div>
                            <div className="profile-tile-details-container">
                                <h2 className="profile-tile-header">Personal Details</h2>
                                <p className="profile-tile-description">View or update your personal details</p>
                            </div>
                            <button className="profile-action-btn" aria-label="Edit Details">Edit Details</button>
                            <div className="profile-action-btn--mobile"></div>
                        </div>

                        <div className="profile-tile-container">
                            <div className="profile-tile-overlay">
                            </div>
                            <div className="profile-tile-icon-container">
                                <div className="profile-tile-icon achievements"></div>
                            </div>
                            <div className="profile-tile-details-container">
                                <h2 className="profile-tile-header">Achievements</h2>
                                <p className="profile-tile-description">View your achievements gained so far</p>
                            </div>
                            <button className="profile-action-btn" aria-label="View Achievements">View Achievements</button>
                            <div className="profile-action-btn--mobile"></div>
                        </div>

                        <div className="profile-tile-container" onClick={() => { navigateToSubPage('/choose-your-guide') }}>
                            <div className="profile-tile-overlay">
                            </div>
                            <div className="profile-tile-icon-container">
                                <div className="profile-tile-icon guide"></div>
                            </div>
                            <div className="profile-tile-details-container">
                                <h2 className="profile-tile-header">Choose your Guide</h2>
                                <p className="profile-tile-description">View or update your choice of guide</p>
                            </div>
                            <button className="profile-action-btn">Edit Guide</button>
                            <div className="profile-action-btn--mobile"></div>
                        </div>

                        <div className="profile-tile-container" onClick={() => { navigateToSubPage('/learners-quiz') }}>
                            <div className="profile-tile-overlay">
                            </div>
                            <div className="profile-tile-icon-container">
                                <div className="profile-tile-icon quiz"></div>
                            </div>
                            <div className="profile-tile-details-container">
                                <h2 className="profile-tile-header">Learner's Quiz</h2>
                                <p className="profile-tile-description">Take the learner's quiz now</p>
                            </div>
                            <button className="profile-action-btn">Take Quiz</button>
                            <div className="profile-action-btn--mobile"></div>
                        </div>

                        <div className="profile-tile-container">
                            <div className="profile-tile-overlay">
                            </div>
                            <div className="profile-tile-icon-container">
                                <div className="profile-tile-icon settings"></div>
                            </div>
                            <div className="profile-tile-details-container">
                                <h2 className="profile-tile-header">Settings</h2>
                                <p className="profile-tile-description">View or update the app settings</p>
                            </div>
                            <button className="profile-action-btn" aria-label="Edit Settings">Edit Settings</button>
                            <div className="profile-action-btn--mobile"></div>
                        </div>

                        <div className="profile-tile-container">
                            <div className="profile-tile-overlay">
                            </div>
                            <div className="profile-tile-icon-container">
                                <div className="profile-tile-icon terms"></div>
                            </div>
                            <div className="profile-tile-details-container">
                                <h2 className="profile-tile-header">T&#38;Cs and Privacy Policy</h2>
                                <p className="profile-tile-description">View our T&#38;Cs and Privacy Policy</p>
                            </div>
                            <button className="profile-action-btn">View</button>
                            <div className="profile-action-btn--mobile"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}