import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";

import { HomeLayout } from './layouts/home-layout/HomeLayout';
import { ProtectedLayout } from './layouts/protected-layout/ProtectedLayout';

import { LoginPage } from './pages/login/LoginPage';
import { LandingPage } from './pages/landing-page/LandingPage';
import { SignupPage } from './pages/signup/SignupPage';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { ChooseYourGuide } from './pages/choose-your-guide/ChooseYourGuide';
import { ProfilePage } from './pages/profile/ProfilePage';
import { PersonalDetailsPage } from './pages/profile/personal-details/PersonalDetailsPage';
import { LearnersQuizPage } from './pages/learners-quiz/LearnersQuizPage';
import { NavbarLayout } from './layouts/fullscreen-layout/NavbarLayout';
import { CoursePage } from './pages/course/CoursePage';
import { ExploreCourses } from './pages/explore-courses/ExploreCourses';
import { CourseOverview } from './pages/course-overview/CourseOverview';
import { ExploreGames } from './pages/explore-games/ExploreGames';
import { GameOverviewPage } from './pages/game-overview/GameOverviewPage';
import { GamePage } from './pages/game/GamePage';
import { AboutUsPage } from './pages/about-us/AboutUsPage';
import { PathwayDashboardPage } from './pages/pathway-dashboard/PathwayDashboardPage';
import { PageNotFound } from './pages/page-not-found/PageNotFound';

function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/signup" element={<SignupPage />}></Route>
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route element={<NavbarLayout />}>
          <Route path="/profile" element={<ProfilePage />}></Route>
          <Route path="/profile/personal-details" element={<PersonalDetailsPage />}></Route>
          <Route path="/dashboard" element={<DashboardPage />}></Route>
          <Route path="/explore-courses" element={<ExploreCourses />}></Route>
          <Route path="/course-overview/:id" element={<CourseOverview />}></Route>
          <Route path="/explore-games" element={<ExploreGames />}></Route>
          <Route path="/game-overview/:id" element={<GameOverviewPage />}></Route>
          <Route path="/games/:id" element={<GamePage />}></Route>
          <Route path="/about-us" element={<AboutUsPage />}></Route>
          <Route path="/pathway-dashboard/:id" element={<PathwayDashboardPage />}></Route>
        </Route>
        <Route path="/choose-your-guide" element={<ChooseYourGuide />}></Route>
        <Route path="/learners-quiz" element={<LearnersQuizPage />}></Route>
        <Route path="/courses/:id" element={<CoursePage />}></Route>
      </Route>
      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
}

export default App;
