import React, { useEffect, useState } from 'react';
import { TeamMemberCards } from '../../components/team-member-cards/TeamMemberCards';
import * as strapiFunctions from '../../services/strapiFunctions';
import './AboutUsPage.scss';

export const AboutUsPage = () => {

    const [aboutUsPageDetails, setAboutUsPageDetails] = useState({});

    useEffect(() => {
        async function getAboutUsPageDetails() {
            let response = await strapiFunctions.getAboutUsPageData();

            if (response.success) {
                let aboutUsPageData = response.responseData.data.data.attributes;

                let aboutUsPageObj = {};

                aboutUsPageObj["title"] = aboutUsPageData["Title"];
                aboutUsPageObj["description"] = aboutUsPageData["Description"];

                let bannerImageDataArray = aboutUsPageData["BannerImage"].data;
                let bannerImageArray = [];

                for (let index in bannerImageDataArray) {
                    let bannerImageData = bannerImageDataArray[index].attributes;
                    bannerImageArray.push(bannerImageData);
                }

                aboutUsPageObj["bannerImages"] = bannerImageArray;

                let aboutUsCardsDataArray = aboutUsPageData["AboutUsCards"];
                let aboutUsCardsArray = [];

                for (let index in aboutUsCardsDataArray) {
                    let aboutUsCardData = aboutUsCardsDataArray[index];

                    let aboutUsCardObj = {};
                    aboutUsCardObj["firstName"] = aboutUsCardData["FirstName"];
                    aboutUsCardObj["lastName"] = aboutUsCardData["LastName"];
                    aboutUsCardObj["role"] = aboutUsCardData["Role"];
                    aboutUsCardObj["message"] = aboutUsCardData["Message"];
                    aboutUsCardObj["profileImage"] = aboutUsCardData["ProfileImage"].data.attributes;
                    aboutUsCardObj["emailID"] = aboutUsCardData["EmailID"];
                    aboutUsCardObj["linkedIn"] = aboutUsCardData["LinkedIn"];

                    aboutUsCardsArray.push(aboutUsCardObj);
                }

                aboutUsPageObj["teamMemberCards"] = aboutUsCardsArray;

                console.log(aboutUsPageObj);

                setAboutUsPageDetails(aboutUsPageObj);
            }
        }

        getAboutUsPageDetails();
    }, []);
    return (
        <div className="about-us-page-container">
            <h1 className="about-us-header">{aboutUsPageDetails.title}</h1>
            <p className="about-us-description">{aboutUsPageDetails.description}</p>
            <div className="about-us-cards-container">
                <TeamMemberCards teamMemberCards={aboutUsPageDetails.teamMemberCards} />
            </div>
        </div>
    )
}