import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as strapiFunction from '../../services/strapiFunctions';
import * as gameNames from '../../constants/gameNames';
import './GameOverviewPage.scss';

export const GameOverviewPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [gameOverview, setGameOverview] = useState({});

    useEffect(() => {
        async function getGameOverviewData() {
            let response = await strapiFunction.getGameOverviewData(gameNames.gameNames[id]);

            let gameOverviewData = response.responseData.data.data[0].attributes;

            let gameOverviewObj = {};

            gameOverviewObj["name"] = gameOverviewData["Name"];
            gameOverviewObj["description"] = gameOverviewData["Description"];
            gameOverviewObj["rules"] = gameOverviewData["Rules"];
            gameOverviewObj["illustration"] = gameOverviewData["Illustration"].data.attributes;

            setGameOverview(gameOverviewObj);
        }

        getGameOverviewData();
    }, [id]);

    const goToGame = useCallback(
        () => {
            navigate("/games/" + id);
        },
        [id, navigate]
    );

    return (
        <div className="game-overview-page-container">
            <div className="game-overview-container">
                <div className="game-banner">
                    <div className="game-details-container">
                        <div className="game-illustration-container">
                            {gameOverview.illustration && <img src={gameOverview.illustration.url} alt={gameOverview.illustration.alternativeText} className="game-illustration-img"></img>}
                        </div>
                        <div className="game-detail-container">
                            <h1 className="game-name">{gameOverview.name}</h1>
                        </div>
                    </div>
                </div>

                <div className="game-description-container">
                    <div className="game-description-header-container">
                        <span className="game-description-icon"></span>
                        <h2 className="game-description-header">Description</h2>
                    </div>
                    <p className="game-description">{gameOverview.description}</p>
                </div>

                <div className="game-structure-container">
                    <div className="game-structure-header-container">
                        <span className="game-structure-icon"></span>
                        <h2 className="game-structure-header">How to Play</h2>
                    </div>
                    <pre className="game-structure">{gameOverview.rules}</pre>
                </div>

                <div className="game-action-btn-container">
                    <button type="button" aria-label="Start Game" className="game-action-btn" onClick={goToGame}>Start Game</button>
                </div>
            </div>
        </div>
    )
}