import React from 'react';
import { Section1 } from '../../course-sections/section-1/Section1';
import { Section3 } from '../../course-sections/section-3/Section3';
import './Layout4.scss';

export const Layout4 = (props) => {

    return (
        <div className="layout4-container">
            <Section1 slideContent={props.slideData.content[0]} />
            <Section3 slideContent={props.slideData.content[1]} />
        </div>
    )
}