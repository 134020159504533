import React, { useState, useEffect, useCallback } from 'react';
import * as apiFunctions from '../../services/apiFunctions';
import * as strapiFunction from '../../services/strapiFunctions';
import { useAuth } from '../../services/useAuth';
import { useNavigate, useSearchParams } from "react-router-dom";
import './ChooseYourGuide.scss';
import { Toast } from '../../components/toast/Toast';
import * as toastStates from '../../constants/toastStates';
import * as url from '../../config/environmentURL';
import * as utilityFunctions from '../../services/utilityFunctions';


export const ChooseYourGuide = () => {

    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let isFirstTimeUser = searchParams.get("isFirstTimeUser");
        if (isFirstTimeUser === "true") {
            setIsFirstTimeUser(true);
        }
    }, [searchParams]);

    // State variable to store the details of all guides
    const [guideArray, setGuideArray] = useState([]);

    // useEffect to fetch all the details of the guides
    useEffect(() => {
        async function getGuideData() {
            let response = await strapiFunction.getGuideData();
            if (response.success) {
                let responseData = response.responseData;
                let guideDataArray = responseData.data.data;

                let guideArray = [];

                for (let index in guideDataArray) {
                    let guideData = guideDataArray[index].attributes;

                    let guide = {};

                    guide["guideName"] = guideData["Name"];
                    guide["guideDescription"] = guideData["Description"];

                    let guideMoods = guideData["guide_moods"].data;

                    let guideMoodImgs = {
                        happy: guideMoods.find((guideMood) => { return guideMood.attributes["Mood"] === "Happy" }).attributes.MoodImage.data.attributes.url,
                        sad: guideMoods.find((guideMood) => { return guideMood.attributes["Mood"] === "Sad" }).attributes.MoodImage.data.attributes.url,
                        cheery: guideMoods.find((guideMood) => { return guideMood.attributes["Mood"] === "Cheery" }).attributes.MoodImage.data.attributes.url
                    }

                    guide["guideMoodImgs"] = guideMoodImgs;

                    guideArray.push(guide);
                }

                setGuideArray(guideArray);
            }
        }
        getGuideData().then(async () => {
            let response = await apiFunctions.getAPIData(url.currentURL + "guide/" + user);
            if (response.success) {
                let guideData = response.responseData.data.results.guideData;
                setChosenGuide(guideData.guide);
            }
        });
    }, [user]);

    // State variable to store the chosen guide 
    const [chosenGuide, setChosenGuide] = useState("");

    // State variable to determine if user confirmed the guide 
    const [isGuideConfirmed, setIsGuideConfirmed] = useState(false);

    // State variable which determines if toast is displayed
    const [isToastDisplayed, setIsToastDisplayed] = useState(false);

    // State variables to store the state, main message and sub message of toast
    const [toastState, setToastState] = useState("");
    const [toastMainMessage, setToastMainMessage] = useState("");
    const [toastSubMessage, setToastSubMessage] = useState("");

    // Function that updates the current choice of guide 
    const chooseGuide = (chosenGuideName) => {
        setChosenGuide(chosenGuideName);
    }

    // Function that confirms submission by user
    const confirmGuide = () => {
        setIsGuideConfirmed(true);
    }

    // Function that submits the guide choice 
    const submitGuide = useCallback(
        async () => {
            if (isGuideConfirmed && chosenGuide !== "") {
                let requestBody = {
                    user: user,
                    guide: chosenGuide
                };

                let responseData = await apiFunctions.postAPIData(url.currentURL + "guide", requestBody);

                utilityFunctions.scrollToTop();

                if (responseData.success) {
                    setIsToastDisplayed(true);
                    setToastState(toastStates.successState);
                    setToastMainMessage("Yay! Guide updated successfully!");
                    setToastSubMessage("Guide has been updated successfully");

                    setTimeout(() => {
                        if (isFirstTimeUser) {
                            navigate("/learners-quiz");
                        }
                        else {
                            navigate("/");
                        }
                    }, 500);
                }
                else {
                    if (responseData.errorData.response.status === 401) {
                        logout();
                    }
                    else {
                        setIsToastDisplayed(true);
                        setToastState(toastStates.errorState);
                        setToastMainMessage("Uh oh! Something went wrong!");
                        setToastSubMessage("Please try again in some time");
                    }
                }
            }
        },
        [chosenGuide, isGuideConfirmed, navigate, user, logout, isFirstTimeUser]
    );

    // useEffect to call submitGuide
    useEffect(() => {
        submitGuide();
    }, [chosenGuide, isGuideConfirmed, submitGuide]);

    // useEffect to confirm guide submission
    useEffect(() => {
        if (isGuideConfirmed && chosenGuide === "") {
            setIsGuideConfirmed(false);
        }
    }, [chosenGuide, isGuideConfirmed]);

    // Function that removes/dismisses toast
    const removeToast = () => {
        setIsToastDisplayed(false);
    }

    return (
        <div className="choose-your-guide-page-container">
            <div className="toast-container">
                {isToastDisplayed && <Toast toastState={toastState} toastMainMessage={toastMainMessage} toastSubMessage={toastSubMessage} removeToast={removeToast} />}
            </div>
            <div className="choose-your-guide-section">
                <div className="choose-your-guide-container">
                    <div className="header-container">
                        <p className="header-greeting">Hey, {user}</p>
                        <h1 className="header-title">Choose your Guide!</h1>
                        <p className="header-subtitle">Your guide helps through your course, motivating you along the way</p>
                    </div>

                    <div className="guides-container">
                        {guideArray.map((guide) =>
                            <div className={`guide-container ${guide.guideName === chosenGuide ? "guide-container--selected" : ""}`} onClick={() => chooseGuide(guide.guideName)} key={guide.guideName}>
                                <span className="guide-name">{guide.guideName}</span>
                                <div className="guide-outer-circle">
                                    <div className="selected-checkmark-container">
                                        <span className="checkmark-icon"></span>
                                    </div>
                                    <div className="guide-inner-circle">
                                        <img className="guide-img" src={guide.guideMoodImgs.happy} alt={`${guide.guideName.toLowerCase()} in a happy mood`}></img>
                                    </div>
                                </div>
                            </div>)}
                    </div>

                    <div className="confirm-guide-btn-container">
                        <button className="cancel-btn" onClick={() => { navigate('/profile') }} aria-label="Cancel update">Cancel</button>
                        <button className="confirm-guide-btn" onClick={confirmGuide} aria-label="Confirm Choice of guide">Confirm Choice</button>
                    </div>
                </div>
            </div>
            <div className="guide-info-section">
                <div className="guide-cards-container">
                    {guideArray.map((guide) =>
                        <div className="guide-card" key={guide.guideName}>
                            <p className="guide-name">{guide.guideName}</p>
                            <div className="guide-moods-container">
                                <img src={guide.guideMoodImgs.sad} alt={`${guide.guideName.toLowerCase()} in a sad mood`} className="guide-mood-img"></img>
                                <img src={guide.guideMoodImgs.cheery} alt={`${guide.guideName.toLowerCase()} in a cheery mood`} className="guide-mood-img"></img>
                            </div>
                            <p className="guide-description">{guide.guideDescription}</p>
                        </div>)}
                </div>
            </div>
        </div>
    )
}