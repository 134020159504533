import React, { useState, useEffect, useCallback } from 'react';
import * as apiFunctions from '../../../services/apiFunctions';
import { useAuth } from '../../../services/useAuth';
import { useNavigate } from "react-router-dom";
import './PersonalDetailsPage.scss';
import { Toast } from '../../../components/toast/Toast';
import * as url from '../../../config/environmentURL';
import * as toastStates from '../../../constants/toastStates';
import * as utilityFunctions from '../../../services/utilityFunctions';


export const PersonalDetailsPage = () => {

    const { user, logout } = useAuth();
    const navigate = useNavigate();

    // State variable to store Form Values
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        emailID: ''
    });

    // useEffect to get user details
    useEffect(() => {
        async function getUserDetails() {
            let response = await apiFunctions.getAPIData(url.currentURL + "user/" + user);
            if (response.success) {
                let responseData = response.responseData.data.results.userData;
                setFormValues(responseData);
            }
            else {
                if (response.errorData.response.status === 401) {
                    logout();
                }
            }
        }
        getUserDetails();
    }, []);

    // State variable to store valid/invalid state of Form values
    const [isFormValueValid, setIsFormValueValid] = useState({
        firstName: true,
        lastName: true,
        gender: true,
        dateOfBirth: true,
        emailID: true
    });

    //State variable which determines if submit was attempted
    const [isSubmitted, setIsSubmitted] = useState(false);

    // State variable which determines if toast is displayed
    const [isToastDisplayed, setIsToastDisplayed] = useState(false);

    // State variables to store the state, main message and sub message of toast
    const [toastState, setToastState] = useState("");
    const [toastMainMessage, setToastMainMessage] = useState("");
    const [toastSubMessage, setToastSubMessage] = useState("");

    // Function to validate a Form value
    const validateInput = (inputFieldName, inputFieldValue) => {

        if (inputFieldName === "firstName" || inputFieldName === "lastName" || inputFieldName === "emailID") {
            setIsFormValueValid((values) => ({
                ...values,
                [inputFieldName]: inputFieldValue === "" ? false : true,
            }));
        }

        if (inputFieldName === "emailID" && inputFieldValue !== "") {
            setIsFormValueValid((values) => ({
                ...values,
                'emailID': (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputFieldValue)) ? true : false,
            }));
        }
    }

    // Function to persist Form values on change
    const handleInputChange = (event) => {
        let inputFieldName = event.target.name;
        let inputFieldValue = event.target.value;

        event.persist();

        validateInput(inputFieldName, inputFieldValue);

        setFormValues((values) => ({
            ...values,
            [inputFieldName]: inputFieldValue,
        }));
    }

    // Function to validate input and set isSubmitted when user attempts submit
    const handleSubmit = useCallback(
        async (event) => {
            setIsSubmitted(false);
            event.preventDefault();

            for (const key in formValues) {
                validateInput(key, formValues[key]);
            }

            setIsSubmitted(true);
        },
        [formValues]
    );

    // Function that determines if all Form values are valid
    const isAllFormValuesValid = useCallback(
        () => {
            for (const key in isFormValueValid) {
                if (!isFormValueValid[key]) {
                    return false;
                }
            }
            return true;
        },
        [isFormValueValid]
    );

    // Function that submits the form by calling the endpoint and handling response
    const submitForm = useCallback(
        async () => {
            let requestBody = {
                ...formValues,
                user: user
            }
            const responseData = await apiFunctions.putAPIData(url.currentURL + "user", requestBody);

            utilityFunctions.scrollToTop();

            setIsToastDisplayed(true);

            if (responseData.success) {
                setToastState(toastStates.successState);
                setToastMainMessage("Yay! Changes saved successfully");
                setToastSubMessage("Your updates were saved successfully");

                setTimeout(() => {
                    navigate('/profile');
                }, 1000);
            }
            else {
                if (responseData.errorData.response.status === 404) {
                    setToastState(toastStates.warningState);
                    setToastMainMessage("Uh oh! The user does not exist!");
                    setToastSubMessage("Please try signing up first and try again");
                }

                else if (responseData.errorData.response.status === 401) {
                    setToastState(toastStates.errorState);
                    setToastMainMessage("Oh no! That didn't work out!");
                    setToastSubMessage("Please check the credentials & try again");
                }

                else {
                    setToastState(toastStates.errorState);
                    setToastMainMessage("Uh oh! Something went wrong!");
                    setToastSubMessage("Please try again in some time");
                }
            }
        },
        [formValues, user, navigate]
    );

    // useEffect to trigger submitForm when input is validated
    useEffect(() => {
        if (isSubmitted && isAllFormValuesValid()) {
            submitForm();
        }
    }, [isFormValueValid, isSubmitted, isAllFormValuesValid, submitForm]);

    // Function that removes/dismisses toast
    const removeToast = () => {
        setIsToastDisplayed(false);
    }

    return (
        <div className="personal-details-page-container">
            <div className="toast-container">
                {isToastDisplayed && <Toast toastState={toastState} toastMainMessage={toastMainMessage} toastSubMessage={toastSubMessage} removeToast={removeToast} />}
            </div>
            <div className="personal-details-section">
                <div className="profile-details-container">
                    <div className="profile-img-container">
                        {formValues.firstName && formValues.lastName && <span className="profile-img-text">{formValues.firstName[0]}{formValues.lastName[0]}</span>}
                    </div>
                    <div className="profile-details">
                        <h1 className="profile-header">Personal Details</h1>
                        {formValues.firstName && formValues.lastName && <p className="profile-username">{formValues.firstName} {formValues.lastName}</p>}
                    </div>
                </div>
                <form onChange={() => setIsSubmitted(false)} onSubmit={handleSubmit} className="form-container">
                    <div className="form-input-container">
                        <label htmlFor="emailID" className="form-input-label">Email</label>
                        <input id="emailID" type="text" name="emailID" value={formValues.emailID} onChange={handleInputChange} className="form-input form-input--readOnly" readOnly='readonly'></input>
                        {!isFormValueValid.emailID && <p className="form-input-validation-msg">The email entered is invalid</p>}
                    </div>

                    <div className="form-input-container">
                        <label htmlFor="firstName" className="form-input-label">First Name</label>
                        <input id="firstName" type="text" name="firstName" value={formValues.firstName} onChange={handleInputChange} className="form-input"></input>
                        {!isFormValueValid.firstName && <p className="form-input-validation-msg">Please enter a first name</p>}
                    </div>

                    <div className="form-input-container">
                        <label htmlFor="lastName" className="form-input-label">Last Name</label>
                        <input id="lastName" type="text" name="lastName" value={formValues.lastName} onChange={handleInputChange} className="form-input"></input>
                        {!isFormValueValid.lastName && <p className="form-input-validation-msg">Please enter a last name</p>}
                    </div>

                    <div className="form-input-container">
                        <label htmlFor="gender" className="form-input-label">Gender<span className="form-input-label--additional"> - optional</span></label>
                        <select id="gender" value={formValues.gender} onChange={handleInputChange} name="gender" className="form-input">
                            <option value="" defaultValue="">Select your gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="non-binary">Non-Binary</option>
                        </select>
                    </div>

                    <div className="form-input-container">
                        <label htmlFor="dateOfBirth" className="form-input-label">Date of Birth<span className="form-input-label--additional"> - optional</span></label>
                        <input id="dateOfBirth" type="date" name="dateOfBirth" value={formValues.dateOfBirth} onChange={handleInputChange} className="form-input"></input>
                    </div>

                    <div className="form-input-container form-btn-container">
                        <button type="button" className="form-cancel-btn" onClick={() => { navigate('/profile') }}>Cancel</button>
                        <button type="submit" className="form-submit-btn">Save Detail</button>
                    </div>
                </form>
            </div>
        </div>
    );
}