import React from 'react';
import './TitleSlide.scss';

export const TitleSlide = (props) => {

    return (
        <div className="title-slide-container">
            <div className="title-slide-card">
                <p className="card-description">Great Job! You finished the lesson!</p>
                <p className="next-up-text">Next Up:</p>
                <h1 className="next-up-title">{props.title}</h1>
            </div>
        </div>
    )
}