import React from 'react';
import { Section1 } from '../../course-sections/section-1/Section1';
import { Section2 } from '../../course-sections/section-2/Section2';
import './Layout3.scss';

export const Layout3 = (props) => {

    return (
        <div className="layout3-container">
            <Section1 slideContent={props.slideData.content[0]} />
            <Section2 slideContent={props.slideData.content[1]} />
        </div>
    )
}