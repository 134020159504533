import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GamesCards } from '../game-cards/GameCards';
import './Conclusion.scss';

export const Conclusion = (props) => {
    const navigate = useNavigate();
    return (
        <div className="conclusion-container">
            <h1 className="conclusion-header">Conclusion</h1>
            <p className="conclusion-subheader">Let's quickly summarise what you have learnt during this course!</p>
            <pre className="conclusion-description">{props.conclusion.conclusionText}</pre>
            {props.conclusion.games && props.conclusion.games.length > 0 &&
                <div className="conclusion-games-container">
                    <h2 className="conclusion-games-header">Checkout these games</h2>
                    <GamesCards games={props.conclusion.games} />
                </div>
            }
            <button className="complete-course-btn" onClick={() => navigate("/explore-courses")}>
                <span className="complete-course-btn-label">Complete Course!</span>
            </button>
        </div>
    )
}