import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../../services/useAuth";
import * as apiFunctions from '../../services/apiFunctions';
import * as url from '../../config/environmentURL';
import { useEffect, useState } from "react";
import './ProtectedLayout.scss';


export const ProtectedLayout = () => {
  const { user, logout } = useAuth();
  const outlet = useOutlet();

  const [isAuthenticated, setIsAuthenticated] = useState();

  useEffect(() => {
    async function getLogin() {
      let response = await apiFunctions.getAPIData(url.currentURL + "login");
      if (!response.success) {
        logout();
      }
      else {
        setIsAuthenticated(true);
      }
    }
    getLogin();
  }, [logout]);

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="protected-layout-container">
      <div className="outlet-container">
        {isAuthenticated && outlet}
      </div>
    </div>
  );
};
