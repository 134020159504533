import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as strapiFunction from '../../services/strapiFunctions';
import * as gameNames from '../../constants/gameNames';
import './GamePage.scss';
import { Twine } from '../../components/game-layouts/twine/Twine';
import { CrosswordGame } from '../../components/game-layouts/crossword/CrosswordGame';

export const GamePage = () => {
    const { id } = useParams();
    const [gameData, setGameData] = useState({});

    useEffect(() => {
        async function getGameData() {
            let response = await strapiFunction.getGameData(gameNames.gameNames[id]);
            let gameResponseData = response.responseData.data.data[0].attributes;

            let gameDataObj = {};
            gameDataObj["name"] = gameResponseData["Name"];
            gameDataObj["type"] = gameResponseData["Type"];
            gameDataObj["configuration"] = gameResponseData["Configuration"];

            setGameData(gameDataObj);
        }

        getGameData();
    }, [id]);

    return (
        <div className="game-page-container">
            <h1 className="game-page-header">{gameData.name}</h1>
            {gameData["type"] === "Twine" && <Twine configuration={gameData["configuration"]} />}
            {gameData["type"] === "Crossword" && <CrosswordGame configuration={gameData["configuration"]} />}
        </div>
    )
}