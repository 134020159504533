import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CourseCards } from '../../components/course-cards/CourseCards';
import * as pathwayNames from '../../constants/pathwayNames';
import * as strapiFunction from '../../services/strapiFunctions';
import './PathwayDashboardPage.scss';
import hourglassIcon from '../../assets/utility-icons/hourglass.svg';
import completedIcon from '../../assets/utility-icons/completed.svg';


export const PathwayDashboardPage = () => {
    const { id } = useParams();

    const [pathwayTitle, setPathwayTitle] = useState("");

    const [courseData, setCourseData] = useState([]);

    const [inProgressCourses, setInProgressCourses] = useState(["STD and STIs", "Gender Identity"]);
    const [completedCourses, setCompletedCourses] = useState(["Sexual Orientation", "Contraception", "Sexual Health and Wellbeing"]);

    const [selectedCourses, setSelectedCourses] = useState([]);

    const [achievements, setAchievements] = useState([
        {
            badge: "Course",
            level: "Bronze"
        },
        {
            badge: "Streak",
            level: "Gold"
        }
    ]);

    const [badges, setBadges] = useState([]);
    const [selectedBadges, setSelectedBadges] = useState([]);

    const [selectedTab, setSelectedTab] = useState("In Progress");
    useEffect(() => {
        setPathwayTitle(pathwayNames.pathwayNames[id]);

        async function getCourseDetails() {
            let response = await strapiFunction.getAllCourses();

            let courseDataArray = response.responseData.data.data;
            let coursesArray = [];

            for (let index in courseDataArray) {
                let courseData = courseDataArray[index].attributes;
                let courseDataObj = {}
                courseDataObj["name"] = courseData["Name"];
                courseDataObj["lessonCount"] = courseData["course_lessons"].data.length;
                courseDataObj["illustration"] = courseData["Illustration"].data.attributes;
                courseDataObj["priority"] = courseData["Priority"];
                courseDataObj["verified"] = courseData["Verified"];
                coursesArray.push(courseDataObj);
            }
            setCourseData(coursesArray);
        }

        async function getBadgeDetails() {
            let response = await strapiFunction.getBadgeData();

            let badgeDataArray = response.responseData.data.data;
            let badgesArray = [];

            for (let index in badgeDataArray) {
                let badgeData = badgeDataArray[index].attributes;
                let badgeDataObj = {};

                badgeDataObj["name"] = badgeData["Name"];
                badgeDataObj["levels"] = {};

                let badgeLevelsArray = badgeData["badge_levels"].data;

                for (let index in badgeLevelsArray) {
                    let badgeLevel = badgeLevelsArray[index].attributes;
                    badgeDataObj["levels"] = {
                        ...badgeDataObj["levels"],
                        [badgeLevel["Level"]]: {
                            description: badgeLevel["Description"],
                            badgeImage: badgeLevel["BadgeImage"].data.attributes
                        }
                    }
                }

                badgesArray.push(badgeDataObj);
                setBadges(badgesArray);
            }
        }

        getCourseDetails();
        getBadgeDetails();
    }, [id]);

    useEffect(() => {
        if (selectedTab === "In Progress") {
            let selectedCoursesArray = courseData.filter((course) => { return inProgressCourses.includes(course.name) });

            setSelectedCourses(selectedCoursesArray);
        }
        else if (selectedTab === "Completed") {
            let selectedCoursesArray = courseData.filter((course) => { return completedCourses.includes(course.name) });
            setSelectedCourses(selectedCoursesArray);
        }
        else if (selectedTab === "Suggested") {
            let selectedCoursesArray = courseData.filter((course) => { return !inProgressCourses.includes(course.name) && !completedCourses.includes(course.name) });
            setSelectedCourses(selectedCoursesArray);
        }
    }, [selectedTab, courseData, inProgressCourses, completedCourses]);

    useEffect(() => {
        let selectedBadgesArray = [];
        for (let achievementIndex in achievements) {
            for (let badgeIndex in badges) {
                if (achievements[achievementIndex].badge === badges[badgeIndex].name) {
                    let selectedBadgeObj = {
                        "name": badges[badgeIndex].name,
                        "badgeImage": badges[badgeIndex].levels[achievements[achievementIndex].level].badgeImage,
                        "description": badges[badgeIndex].levels[achievements[achievementIndex].level].description
                    }
                    selectedBadgesArray.push(selectedBadgeObj);
                }
            }
        }
        setSelectedBadges(selectedBadgesArray);
    }, [achievements, badges]);


    return (
        <div className="pathway-dashboard-page-container">
            <h1 className="pathway-dashboard-header">{pathwayTitle}</h1>
            <div className="pathway-details-container">
                <div className="stats-container">
                    <h2 className="stats-container-header">My Stats</h2>
                    <div className="stats-details-container">
                        <div className="stat-detail-container">
                            <img className="stat-icon" src={completedIcon} alt="streak-icon"></img>
                            <div className="stat-detail-content-container">
                                <p className="stat-detail-value">10</p>
                                <p className="stat-detail-label">Courses Completed</p>
                            </div>
                        </div>

                        <div className="stat-detail-container">
                            <img className="stat-icon" src={hourglassIcon} alt="hourglass-icon"></img>
                            <div className="stat-detail-content-container">
                                <p className="stat-detail-value">65</p>
                                <p className="stat-detail-label">Hours Spent</p>
                            </div>
                        </div>
                    </div>
                    <h3 className="badges-header">Achievements</h3>
                    <div className="badges-container">
                        {selectedBadges && selectedBadges.map((selectedBadge) =>
                            <div className="badge-container" key={selectedBadge.name}>
                                <img className="badge-img" src={selectedBadge.badgeImage.url} alt={selectedBadge.badgeImage.alternativeText}></img>
                                <p className="badge-name">{selectedBadge.name}</p>
                                <p className="badge-description">{selectedBadge.description}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="tab-container">
                <div className="tab-inner-container">
                    <button className={`tab ${selectedTab === "In Progress" ? "tab--selected" : ""}`} onClick={() => { setSelectedTab("In Progress") }}>In Progress</button>
                    <button className={`tab ${selectedTab === "Completed" ? "tab--selected" : ""}`} onClick={() => { setSelectedTab("Completed") }}>Completed</button>
                    <button className={`tab ${selectedTab === "Suggested" ? "tab--selected" : ""}`} onClick={() => { setSelectedTab("Suggested") }}>Suggested</button>
                </div>
            </div>
            <CourseCards courses={selectedCourses}></CourseCards>
        </div>
    )
}