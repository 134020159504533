import React from 'react';
import './LearningOutcomes.scss'
import learningGoalDoImg from '../../assets/illustrations/lg-do.png';
import learningGoalFeelImg from '../../assets/illustrations/lg-feel.png';
import learningGoalKnowImg from '../../assets/illustrations/lg-know.png';

export const LearningOutcomes = (props) => {

    const startCourse = () => {
        props.startCourse();
    }

    return (
        <div className="learning-outcomes-container">
            <h1 className="learning-outcomes-header">Learning Outcomes</h1>
            <p className="learning-outcomes-description">At the end of this course, you will be able to..</p>
            <div className="learning-outcomes-card-container">
                <div className="learning-outcomes-card">
                    <div className="card-header-container">
                        <div className="card-header-icon-outer-circle">
                            <div className="card-header-icon-inner-circle">
                                <img src={learningGoalKnowImg} alt="learning-goal-know-img" className="card-header-icon-img" />
                            </div>
                        </div>
                        <p className="card-header-title">Know</p>
                    </div>
                    <ul className="learning-outcomes-list">
                        {props.learningOutcomes.filter((learningOutcome) => { return learningOutcome.type === "Know" }).map((learningOutcome) =>
                            <li className="learning-outcomes-list-item" key={learningOutcome.outcome}>{learningOutcome.outcome}</li>
                        )}
                    </ul>
                </div>

                <div className="learning-outcomes-card">
                    <div className="card-header-container">
                        <div className="card-header-icon-outer-circle">
                            <div className="card-header-icon-inner-circle">
                                <img src={learningGoalFeelImg} alt="learning-goal-feel-img" className="card-header-icon-img" />
                            </div>
                        </div>
                        <p className="card-header-title">Feel</p>
                    </div>
                    <ul className="learning-outcomes-list">
                        {props.learningOutcomes.filter((learningOutcome) => { return learningOutcome.type === "Feel" }).map((learningOutcome) =>
                            <li className="learning-outcomes-list-item" key={learningOutcome.outcome}>{learningOutcome.outcome}</li>
                        )}
                    </ul>
                </div>

                <div className="learning-outcomes-card">
                    <div className="card-header-container">
                        <div className="card-header-icon-outer-circle">
                            <div className="card-header-icon-inner-circle">
                                <img src={learningGoalDoImg} alt="learning-goal-do-img" className="card-header-icon-img" />
                            </div>
                        </div>
                        <p className="card-header-title">Do</p>
                    </div>
                    <ul className="learning-outcomes-list">
                        {props.learningOutcomes.filter((learningOutcome) => { return learningOutcome.type === "Do" }).map((learningOutcome) =>
                            <li className="learning-outcomes-list-item" key={learningOutcome.outcome}>{learningOutcome.outcome}</li>
                        )}
                    </ul>
                </div>
            </div>
            <button className="start-course-btn" onClick={startCourse}>Let's get started</button>
        </div>
    )
}