import React from 'react';
import { Section1 } from '../../course-sections/section-1/Section1';
import './Layout1.scss';

export const Layout1 = (props) => {

    return (
        <div className="layout1-container">
            {props.slideData.content.map((slide) =>
                <Section1 key={slide.Heading} slideContent={slide} />
            )}
        </div>
    )
}