import React, { useEffect, useState } from 'react';
import { useAuth } from '../../services/useAuth';
import { useNavigate } from "react-router-dom";
import { PathwayCards } from '../../components/pathways-cards/PathwayCards';
import * as strapiFunctions from '../../services/strapiFunctions';
import * as apiFunctions from '../../services/apiFunctions';
import * as url from '../../config/environmentURL';
import './DashboardPage.scss';
import streakIcon from '../../assets/utility-icons/streak.svg';
import bestStreakIcon from '../../assets/utility-icons/best-streak.svg';

export const DashboardPage = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [pathways, setPathways] = useState([]);
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        async function getPathwaysDetails() {
            let response = await strapiFunctions.getPathwaysData();

            let pathwaysData = response.responseData.data.data;
            let pathwaysDataArray = [];

            for (let index in pathwaysData) {
                let pathwayData = pathwaysData[index].attributes;
                let pathwayObj = {};

                pathwayObj["name"] = pathwayData["Name"];
                pathwayObj["courseCount"] = pathwayData["courses"].data.length;
                pathwayObj["illustration"] = pathwayData["Illustration"].data.attributes;

                pathwaysDataArray.push(pathwayObj);
            }

            setPathways(pathwaysDataArray);
        }

        async function getUserDetails() {
            let response = await apiFunctions.getAPIData(url.currentURL + "user/" + user);
            if (response.success) {
                let responseData = response.responseData.data.results.userData;
                setUserDetails(responseData);
            }
            else {
                if (response.errorData.response.status === 401) {
                    logout();
                }
            }
        }

        getPathwaysDetails();
        getUserDetails();
    }, [logout, user]);

    return (
        <div className="dashboard-page-container">
            <div className="dashboard-header-section">
                <div className="dashboard-header-container">
                    {userDetails.firstName && userDetails.lastName && <h1 className="dashboard-header">Hi, {userDetails.firstName} {userDetails.lastName}</h1>}
                    <p className="dashboard-subheader">Welcome to your dashboard</p>
                </div>
                <div className="dashboard-stats-container">
                    {/* <p className="dashboard-stats-header">Stats</p> */}
                    <div className="stats-details-container">
                        <div className="stat-detail-container">
                            <img className="stat-icon" src={streakIcon} alt="streak-icon"></img>
                            <div className="stat-detail-content-container">
                                <p className="stat-detail-value">10</p>
                                <p className="stat-detail-label">Current Streak</p>
                            </div>
                        </div>

                        <div className="stat-detail-container">
                            <img className="stat-icon" src={bestStreakIcon} alt="streak-icon"></img>
                            <div className="stat-detail-content-container">
                                <p className="stat-detail-value">65</p>
                                <p className="stat-detail-label">Best Streak</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pathways-section">
                <PathwayCards pathways={pathways} />
            </div>
        </div>
    );
}