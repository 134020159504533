import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as courseNames from '../../constants/courseNames';
import './CourseCards.scss';
import verifiedIcon from '../../assets/utility-icons/verified.svg';

export const CourseCards = (props) => {

    const navigate = useNavigate();


    const goToCourseOverview = useCallback(
        (courseName) => {
            let courseNamesObj = courseNames.courseNames;
            let courseNameKey = Object.keys(courseNamesObj).find(key => courseNamesObj[key] === courseName);
            navigate("/course-overview/" + courseNameKey);
        },
        [navigate]
    );

    return (
        <div className="courses-container">
            {props.courses && props.courses.length > 0 && props.courses.map((course) =>
                <div className="course-card" key={course.name} onClick={() => { goToCourseOverview(course.name) }}>
                    <div className="course-banner">
                        <img src={course.illustration.url} alt={course.illustration.alternativeText} className="course-banner-img"></img>
                    </div>
                    <p className="course-name">{course.name}</p>
                    <p className="course-lesson-count">{course.lessonCount} Lessons</p>
                    <div className="course-btn-container">
                        <div className={`verified-tag ${!course.verified ? "verified-tag--hidden" : ""}`}>
                            <img className="verified-icon" src={verifiedIcon} alt="verified-icon"></img>
                            <span className="verified-label">Verified</span>
                        </div>
                        <button className="course-btn">
                            <span className="course-btn-icon"></span>
                        </button>
                    </div>
                </div>)
            }
        </div >
    )

}