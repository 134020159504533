import React from 'react';
import './Section2.scss';

export const Section2 = (props) => {

    return (
        <div className="section2-container">
            <h1 className="content-header">{props.slideContent.Heading}</h1>
            <div className="cards-container">
                {props.slideContent.Cards && props.slideContent.Cards.length > 0 && props.slideContent.Cards.map((card) => <div className="card-container" key={card.Heading}>
                    <p className="card-title">{card.Heading}</p>
                    <p className="card-description">{card.Description}</p>
                </div>)}
            </div>
        </div>
    )
}