import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import * as apiFunctions from '../../services/apiFunctions';
import "./SignupPage.scss";
import helperMonkeyImg from '../../assets/illustrations/helper-monkey.svg';
import { Toast } from '../../components/toast/Toast';
import * as toastStates from '../../constants/toastStates';
import * as utilityFunctions from '../../services/utilityFunctions';
import * as url from '../../config/environmentURL';
import * as strapiFunctions from '../../services/strapiFunctions';
import logoImg from '../../assets/logo/icon-logo.svg';

export const SignupPage = () => {

    const navigate = useNavigate();

    const [illustration, setIllustration] = useState();

    // State variable to store Form Values
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        emailID: '',
        password: '',
        confirmPassword: ''
    });

    // State variable to store valid/invalid state of Form values
    const [isFormValueValid, setIsFormValueValid] = useState({
        firstName: true,
        lastName: true,
        gender: true,
        dateOfBirth: true,
        emailID: true,
        password: true,
        confirmPassword: true
    });

    // State variable which determines if Form values are validated
    const [isValidated, setIsValidated] = useState(false);

    // State variable whic determines if use is in first step
    const [isFirstStep, setIsFirstStep] = useState(true);

    // State variable which determines if toast is displayed
    const [isToastDisplayed, setIsToastDisplayed] = useState(false);

    // State variables to store the state, main message and sub message of toast
    const [toastState, setToastState] = useState("");
    const [toastMainMessage, setToastMainMessage] = useState("");
    const [toastSubMessage, setToastSubMessage] = useState("");

    //State variable which determines if submit was attempted
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        async function getSignupPageDetails() {
            let response = await strapiFunctions.getSignupPageData();
            if (response.success) {
                let responseData = response.responseData.data.data.attributes;
                let illustrationData = responseData["Illustration"].data.attributes;
                setIllustration(illustrationData);
            }
        }

        getSignupPageDetails();
    }, []);

    // Function to validate a Form value
    const validateInput = useCallback(
        (inputFieldName, inputFieldValue) => {
            if (inputFieldName === "firstName" || inputFieldName === "lastName" || inputFieldName === "emailID" || inputFieldName === "password" || inputFieldName === "confirmPassword") {
                setIsFormValueValid((values) => ({
                    ...values,
                    [inputFieldName]: inputFieldValue === "" ? false : true,
                }));
            }

            if (inputFieldName === "emailID" && inputFieldValue !== "") {
                setIsFormValueValid((values) => ({
                    ...values,
                    'emailID': (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputFieldValue)) ? true : false,
                }));
            }

            if (inputFieldName === "confirmPassword" && inputFieldValue !== "" && formValues.password !== "") {
                setIsFormValueValid((values) => ({
                    ...values,
                    'confirmPassword': inputFieldValue === formValues.password ? true : false,
                }));
            }

            if (inputFieldName === "password" && inputFieldValue !== "") {
                setIsFormValueValid((values) => ({
                    ...values,
                    'password': inputFieldValue.length < 8 ? false : true,
                }));
            }
        },
        [formValues]
    );

    // Function that determines if all Form values are valid
    const isAllFormValuesValid = useCallback(
        () => {
            for (const key in isFormValueValid) {
                if (!isFormValueValid[key]) {
                    return false;
                }
            }
            return true;
        },
        [isFormValueValid]
    );

    // Function to validate input and set isSubmitted when user attempts submit
    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            for (const key in formValues) {
                validateInput(key, formValues[key]);
            }

            setIsSubmitted(true);
        },
        [formValues, validateInput]
    );

    // Function that submits the form by calling the endpoint and handling response
    const submitForm = useCallback(
        async () => {
            const responseData = await apiFunctions.postAPIData(url.currentURL + "signup", formValues);

            utilityFunctions.scrollToTop();
            setIsToastDisplayed(true);

            if (!responseData.success) {
                if (responseData.errorData.response.status === 409) {
                    setToastState(toastStates.warningState);
                    setToastMainMessage("Uh oh! This user already exists!");
                    setToastSubMessage("Please try logging in instead");
                }

                else {
                    setToastState(toastStates.errorState);
                    setToastMainMessage("Uh oh! Something went wrong!");
                    setToastSubMessage("Please try again in some time");
                }
            }
            else {
                setToastState(toastStates.successState);
                setToastMainMessage("Yay! User signed up successfully!");
                setToastSubMessage("You were signed up successfully");

                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            }
        },
        [formValues, navigate]
    );

    // useEffect to trigger submitForm when input is validated
    useEffect(() => {
        if (isSubmitted && isAllFormValuesValid()) {
            submitForm();
        }
    }, [isSubmitted, isAllFormValuesValid, submitForm]);

    // Function to persist Form values on change
    const handleInputChange = useCallback(
        (event) => {
            let inputFieldName = event.target.name;
            let inputFieldValue = event.target.value;

            event.persist();

            validateInput(inputFieldName, inputFieldValue);

            setFormValues((values) => ({
                ...values,
                [inputFieldName]: inputFieldValue,
            }));
        },
        [validateInput]
    );

    // useEffect to navigate to next step when Form values are validated
    useEffect(() => {
        if (isValidated && isFormValueValid.firstName && isFormValueValid.lastName) {
            setIsFirstStep(false);
            utilityFunctions.scrollToTop();
        }
    }, [isValidated, isFormValueValid]);

    // useEffect to reset validation flag on navigation to next step
    useEffect(() => {
        setIsSubmitted(false);
        if (!isFirstStep) {
            setIsValidated(false);
        }
    }, [isFirstStep])

    // Function that navigates user to next step
    const goToNextStep = useCallback(
        () => {
            // Reset validation flag on attempt to navigation to next step.
            setIsValidated(false);
            validateInput("firstName", formValues.firstName);
            validateInput("lastName", formValues.lastName);
            setIsValidated(true);
        },
        [formValues, validateInput]
    );

    // Function that navigates user back to previous step
    const goToPreviousStep = () => {
        setIsFirstStep(true);
        utilityFunctions.scrollToTop();
    }

    // Function that removes/dismisses toast
    const removeToast = () => {
        setIsToastDisplayed(false);
    }

    return (
        <div className="signup-form-page-container">
            <div className="toast-container">
                {isToastDisplayed && <Toast toastState={toastState} toastMainMessage={toastMainMessage} toastSubMessage={toastSubMessage} removeToast={removeToast} />}
            </div>
            <div className="signup-form-section">
                <div className="logo-container" onClick={() => { navigate("/") }}>
                    <img src={logoImg} alt="logo-icon" className="logo-img"></img>
                </div>
                <div className="form-section">
                    <div className="helper-illustration-container">
                        <div className="illustration-outer-circle">
                            <div className="illustration-inner-circle">
                                <img src={helperMonkeyImg} className="illustration-img" alt="helper-monkey-illustration"></img>
                            </div>
                        </div>
                    </div>
                    <div className="header-container">
                        <h1 className="header-title">Hello there!</h1>
                        <p className="header-subtitle">Nice to meet you! Enter your details to get started</p>
                    </div>
                    <div className="form-step-indicator-container">
                        <div className="form-steps-label-container">
                            <span className="form-step-label form-step-label--active">Details</span>
                            <span className={`form-step-label ${!isFirstStep ? "form-step-label--active" : ""}`}>Connect</span>
                        </div>
                        <div className="form-steps-container">
                            <div className="step-indicator step-indicator--active">
                                <span className="step-label">1</span>
                            </div>

                            <hr className={`step-divider ${!isFirstStep ? "step-divider--active" : ""}`} />

                            <div className={`step-indicator ${!isFirstStep ? "step-indicator--active" : ""}`}>
                                <span className="step-label">2</span>
                            </div>
                        </div>
                    </div>
                    <form onChange={() => { setIsValidated(false); setIsSubmitted(false) }} onSubmit={handleSubmit} className="form-container">
                        {isFirstStep && <div className="form-container-sub-section">
                            <div className="form-input-container">
                                <label htmlFor="firstName" className="form-input-label">First Name</label>
                                <input id="firstName" type="text" aria-label="first name" name="firstName" value={formValues.firstName} onChange={handleInputChange} className="form-input"></input>
                                {!isFormValueValid.firstName && <p className="form-input-validation-msg">Please enter a first name</p>}
                            </div>

                            <div className="form-input-container">
                                <label htmlFor="lastName" className="form-input-label">Last Name</label>
                                <input id="lastName" type="text" aria-label="last name" name="lastName" value={formValues.lastName} onChange={handleInputChange} className="form-input"></input>
                                {!isFormValueValid.lastName && <p className="form-input-validation-msg">Please enter a last name</p>}
                            </div>

                            <div className="form-input-container">
                                <label htmlFor="gender" className="form-input-label">Gender<span className="form-input-label--additional"> - optional</span></label>
                                <select id="gender" value={formValues.gender} onChange={handleInputChange} name="gender" className="form-input">
                                    <option value="" disabled defaultValue="">Select your gender</option>
                                    <option aria-label="Male" value="male">Male</option>
                                    <option aria-label="Female" value="female">Female</option>
                                    <option aria-label="Non-Binary" value="non-binary">Non-Binary</option>
                                </select>
                            </div>

                            <div className="form-input-container">
                                <label htmlFor="dateOfBirth" className="form-input-label">Date of Birth<span className="form-input-label--additional"> - optional</span></label>
                                <input id="dateOfBirth" type="date" aria-label="Date of Birth" name="dateOfBirth" value={formValues.dateOfBirth} onChange={handleInputChange} className="form-input"></input>
                                {!isFormValueValid.dateOfBirth && <p className="form-input-validation-msg">Please enter a last name</p>}
                            </div>
                        </div>}

                        {isFirstStep && <div className="form-input-container">
                            <button type="button" aria-label="Next" className="next-section-btn" onClick={goToNextStep}>Next</button>
                        </div>}

                        {!isFirstStep && <div className="form-container-sub-section">
                            <div className="form-input-container">
                                <label htmlFor="emailID" className="form-input-label">Email</label>
                                <input id="emailID" type="text" name="emailID" aria-label="Email ID" value={formValues.emailID} onChange={handleInputChange} className="form-input"></input>
                                {!isFormValueValid.emailID && <p className="form-input-validation-msg">The email entered is invalid</p>}
                            </div>

                            <div className="form-input-container">
                                <label htmlFor="password" className="form-input-label">Password<span className="form-input-label--additional"> - min. 8 characters</span></label>
                                <input id="password" type="password" name="password" aria-label="password" value={formValues.password} onChange={handleInputChange} className="form-input"></input>
                                {!isFormValueValid.password && <p className="form-input-validation-msg">The password entered is too short</p>}
                            </div>

                            <div className="form-input-container">
                                <label htmlFor="confirmPassword" className="form-input-label">Confirm password</label>
                                <input id="confirmPassword" type="password" name="confirmPassword" value={formValues.confirmPassword} onChange={handleInputChange} className="form-input"></input>
                                {!isFormValueValid.confirmPassword && <p className="form-input-validation-msg">Passwords do not match</p>}
                            </div>
                        </div>}

                        {!isFirstStep && <div className="form-input-container">
                            <button type="submit" aria-label="Sign Up" className="form-submit-btn">Sign Up</button>
                        </div>}

                        {!isFirstStep && <div className="form-input-container">
                            <button type="button" aria-label="Back" className="previous-section-btn" onClick={goToPreviousStep}><span className="previous-section-btn-icon"></span>Go Back</button>
                        </div>}
                    </form>
                </div>
                <div className="signup-link-section">
                    <p className="signup-link-message">Already have an account? <a aria-label="login if you aleady have an account" href="/login" className="signup-link">Log in</a></p>
                </div>
            </div>
            <div className="illustration-section">
                {illustration && <img src={illustration.url} alt={illustration.alternativeText} className="illustration-img"></img>}
            </div>
        </div>
    );
}