import axios from 'axios';

export const getAPIData = async (endpoint) => {
    try {
        const responseData = await axios.get(endpoint, { headers: { "x-access-token": localStorage.getItem("token") } });
        return {
            "success": true,
            "responseData": responseData,
            "errorData": {}
        };
    } catch (error) {
        return {
            "success": false,
            "responseData": {},
            "errorData": error
        };
    }
}

export const postAPIData = async (endpoint, requestData) => {
    try {
        const responseData = await axios.postForm(endpoint, requestData, { headers: { "x-access-token": localStorage.getItem("token") } });
        return {
            "success": true,
            "responseData": responseData,
            "errorData": {}
        };
    } catch (error) {
        return {
            "success": false,
            "responseData": {},
            "errorData": error
        };
    }
}

export const putAPIData = async (endpoint, requestData) => {
    try {
        const responseData = await axios.putForm(endpoint, requestData, { headers: { "x-access-token": localStorage.getItem("token") } });
        return {
            "success": true,
            "responseData": responseData,
            "errorData": {}
        };
    } catch (error) {
        return {
            "success": false,
            "responseData": {},
            "errorData": error
        };
    }
}


