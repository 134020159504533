import React, { useState } from 'react';
import './Toast.scss';
import closeIcon from '../../assets/utility-icons/close.svg';

export const Toast = (props) => {

    const [isToastDismissed, setIsToastDismissed] = useState(false);

    const dismissToast = () => {
        setIsToastDismissed(true);

        setTimeout(()=> {
            props.removeToast();
        }, 1000);
    }
    
    return (
        <div className={`toast ${isToastDismissed ? "dismissed" : ""}`}>
            <div className={`toast-side-border ${props.toastState}`}>
            </div>

            <div className={`toast-icon-container ${props.toastState}`}>
                <div className={`${props.toastState}-icon toast-icon`}>
                </div>
            </div>

            <div className="toast-message-container">
                <p className="toast-main-message">{props.toastMainMessage}</p>
                <p className="toast-sub-message">{props.toastSubMessage}</p>
            </div>

            <div className="toast-dismiss-btn-container">
                <button className="toast-dismiss-btn" onClick={dismissToast}></button>
            </div>

        </div>
    )
}