import React from 'react';
import './Section1.scss';

export const Section1 = (props) => {
    return (
        <div className="section1-container">
            <div className="content-container">
                <h1 className="content-header">{props.slideContent.Heading}</h1>
                <pre className="content-description">{props.slideContent.Description}</pre>
            </div>
            <div className="illustration-container">
                <img src={props.slideContent.Illustration.data.attributes.url} className="illustration-img" alt={props.slideContent.Illustration.data.attributes.alternativeText}></img>
            </div>
        </div>
    )
}