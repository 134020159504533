import React from 'react';
import './TeamMemberCards.scss';

export const TeamMemberCards = (props) => {
    return (
        <div className="team-member-cards-container">
            {props.teamMemberCards && props.teamMemberCards.length > 0 && props.teamMemberCards.map((teamMemberCard) =>
                <div className="team-member-card" key={teamMemberCard.firstName}>
                    <div className="team-member-card-inner">
                        <div className="team-member-card-front">
                            <div className="card-hover-action-btn">
                                <span className="card-hover-action-btn-icon"></span>
                            </div>
                            <div className="team-member-role-container">
                                <p className={`team-member-role ${teamMemberCard.role === "Supervisor" ? "team-member-role--supervisor" : ""}`}>{teamMemberCard.role}</p>
                            </div>
                            <div className="team-member-profile-img-container">
                                <img src={teamMemberCard.profileImage.url} alt={teamMemberCard.profileImage.alternativeText} className="team-member-profile-img"></img>
                            </div>
                            <div className="team-member-name-container">
                                <p className="team-member-name">{teamMemberCard.firstName}</p>
                                <p className="team-member-name">{teamMemberCard.lastName}</p>
                            </div>
                        </div>
                        <div className="team-member-card-back">
                            <div className="team-member-quote-container">
                                <p className="team-member-quote">
                                    " {teamMemberCard.message} "
                                </p>
                            </div>
                            <div className="contact-links-container">
                                <a href={teamMemberCard.linkedIn} target="_blank" rel="noreferrer" className="contact-link">
                                    <span className="linkedin-icon">
                                    </span>
                                </a>
                                <a href={`mailto: ${teamMemberCard.emailID}`} target="_blank" rel="noreferrer" className="contact-link">
                                    <span className="email-icon">
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}