import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as apiFunctions from '../../services/apiFunctions';
import * as strapiFunction from '../../services/strapiFunctions';
import * as courseNames from '../../constants/courseNames';
import * as url from '../../config/environmentURL';
import './CourseOverview.scss';
import verifiedIcon from '../../assets/utility-icons/verified.svg';
import { useAuth } from '../../services/useAuth';

export const CourseOverview = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    const [courseOverview, setCourseOverview] = useState({});
    const [courseLessonData, setCourseLessonData] = useState({});

    useEffect(() => {
        async function getCourseOverviewData() {
            let response = await strapiFunction.getCourseOverviewData(courseNames.courseNames[id]);

            let courseOverviewData = response.responseData.data.data[0].attributes;

            let courseOverviewObj = {};

            courseOverviewObj["name"] = courseOverviewData["Name"];
            courseOverviewObj["description"] = courseOverviewData["Description"];
            courseOverviewObj["structure"] = courseOverviewData["Structure"];
            courseOverviewObj["timeTaken"] = courseOverviewData["Time"];
            courseOverviewObj["verified"] = courseOverviewData["Verified"];
            courseOverviewObj["verifiedBy"] = courseOverviewData["VerifiedBy"];
            courseOverviewObj["illustration"] = courseOverviewData["Illustration"].data.attributes;

            let resourcesData = courseOverviewData["resources"].data;
            let resourcesArray = [];

            for (let index in resourcesData) {
                let resourceData = resourcesData[index].attributes;
                let resource = {};
                resource["name"] = resourceData["Name"];
                resource["url"] = resourceData["URL"];
                resourcesArray.push(resource);
            }

            courseOverviewObj["resources"] = resourcesArray;

            let courseLessonArray = courseOverviewData["course_lessons"].data;
            let courseLessonDataObj = {};

            for (let index in courseLessonArray) {
                let courseLesson = courseLessonArray[index].attributes;
                let courseLessonSlidesArray = courseLesson["course_slides"].data;

                let courseLessonSlides = [];
                for (let index in courseLessonSlidesArray) {
                    let courseLessonSlide = courseLessonSlidesArray[index].attributes;
                    courseLessonSlides.push(courseLessonSlide["Name"]);
                }

                courseLessonDataObj = {
                    ...courseLessonDataObj,
                    [courseLesson["Name"]]: courseLessonSlides
                }
            }

            setCourseLessonData(courseLessonDataObj);

            setCourseOverview(courseOverviewObj);
        }

        getCourseOverviewData();
    }, [id]);

    const logCourseProgress = useCallback(
        async () => {
            let courseProgressResponse = await apiFunctions.getAPIData(url.currentURL + `course-progress?emailID=${user}&courseName=${courseNames.courseNames[id]}`);

            if (Object.keys(courseProgressResponse.responseData.data.results).length === 0 || Object.keys(courseProgressResponse.responseData.data.results.courseData).length === 0) {
                let slidesProgressData = {};

                for (const [lesson, slides] of Object.entries(courseLessonData)) {
                    let slideProgress = {};
                    for (let index in slides) {
                        slideProgress = {
                            ...slideProgress,
                            [slides[index]]: false
                        };
                    }
                    slidesProgressData = {
                        ...slidesProgressData,
                        [lesson]: slideProgress
                    };
                }

                let courseProgressData = {
                    "emailID": user,
                    "courseName": courseNames.courseNames[id],
                    "slidesProgress": slidesProgressData
                }

                let formData = new FormData();
                formData.append("courseProgressData", JSON.stringify(courseProgressData));

                let courseProgressUpdateResponse = await apiFunctions.postAPIData(url.currentURL + "course-progress", formData);

                if (courseProgressUpdateResponse.success) {
                    navigate("/courses/" + id);
                }
            }
            else {
                navigate("/courses/" + id);
            }
        },
        [courseLessonData, id, user, navigate]
    );

    const goToCourse = useCallback(
        async () => {
            await logCourseProgress();
        },
        [logCourseProgress]
    );

    return (
        <div className="course-overview-page-container">
            <div className="course-banner">
                <div className="course-details-container">
                    <div className="course-illustration-container">
                        {courseOverview.illustration && <img src={courseOverview.illustration.url} alt={courseOverview.illustration.alternativeText} className="course-illustration-img"></img>}
                    </div>
                    <div className="course-detail-container">
                        <h1 className="course-name">{courseOverview.name}</h1>
                        <div className="course-time-taken-container">
                            <p className="course-time-taken-icon"></p>
                            <p className="course-time-taken">{courseOverview.timeTaken}</p>
                        </div>
                        {courseOverview.verified &&
                            <div className="course-verfication-details">
                                <img src={verifiedIcon} alt="verified-icon" className="course-verification-icon"></img>
                                <p className="course-verification-label">Verified By: </p>
                                <p className="course-verification-name">{courseOverview.verifiedBy}</p>
                            </div>}
                    </div>
                </div>
            </div>

            <div className="course-description-container">
                <div className="course-description-header-container">
                    <span className="course-description-icon"></span>
                    <h2 className="course-description-header">Description</h2>
                </div>
                <p className="course-description">{courseOverview.description}</p>
            </div>

            <div className="course-structure-container">
                <div className="course-structure-header-container">
                    <span className="course-structure-icon"></span>
                    <h2 className="course-structure-header">What to expect</h2>
                </div>
                <pre className="course-structure">{courseOverview.structure}</pre>
            </div>

            <div className="course-resources-container">
                <div className="course-resources-header-container">
                    <span className="course-resources-icon"></span>
                    <h2 className="course-resources-header">Resources</h2>
                </div>
                <div className="course-resources">
                    This course was developed from content by -
                    <ul className="course-resources-list">
                        {courseOverview.resources && courseOverview.resources.length > 0 && courseOverview.resources.map((resource) => <li className="course-resources-list-item" key={resource.name}>
                            <a href={resource.url} target="_blank" rel="noreferrer" className="course-resources-link">{resource.name}</a>
                        </li>)}
                    </ul>
                    Check out these resources for more information.
                </div>
            </div>

            <div className="course-action-btn-container">
                <button type="button" aria-label="Start Course" className="course-action-btn" onClick={goToCourse}>Start Course</button>
            </div>

        </div>
    )
}
