import React, { useState, useEffect } from 'react';
import * as strapiFunction from '../../services/strapiFunctions';
import './ExploreCourses.scss';
import { CourseCards } from '../../components/course-cards/CourseCards';

export const ExploreCourses = () => {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        async function getCoursesByCategory() {
            let response = await strapiFunction.getCoursesByCategoryData();

            let categoryDataArray = response.responseData.data.data;
            let categoryArray = [];

            for (let index in categoryDataArray) {
                let categoryData = categoryDataArray[index].attributes;

                let categoryObj = {};
                categoryObj["name"] = categoryData["Category"];

                let coursesDataArray = categoryData["courses"].data;
                let coursesArray = [];

                for (let index in coursesDataArray) {
                    let courseData = coursesDataArray[index].attributes;
                    let courseDataObj = {}
                    courseDataObj["name"] = courseData["Name"];
                    courseDataObj["lessonCount"] = courseData["course_lessons"].data.length;
                    courseDataObj["illustration"] = courseData["Illustration"].data.attributes;
                    courseDataObj["verified"] = courseData["Verified"];
                    coursesArray.push(courseDataObj);
                }
                categoryObj["courses"] = coursesArray;

                categoryArray.push(categoryObj);
            }
            setCategories(categoryArray);
        }

        getCoursesByCategory();
    }, []);

    return (
        <div className="explore-courses-page-container">
            <h1 className="explore-courses-header">Explore Courses</h1>
            <div className="categories-container">
                {categories.length > 0 && categories.map((category) =>
                    <div className="category-container" key={category.name}>
                        <p className="category-title">{category.name}</p>
                        <CourseCards courses={category.courses} />
                    </div>)}
            </div>
        </div>
    )
}