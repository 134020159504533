import React, { useState, useEffect, useCallback } from 'react';
import { GamesCards } from '../../components/game-cards/GameCards';
import * as strapiFunction from '../../services/strapiFunctions';
import './ExploreGames.scss';

export const ExploreGames = () => {
    const [games, setGames] = useState([]);

    useEffect(() => {
        async function getGames() {
            let response = await strapiFunction.getGamesData();
            let gamesData = response.responseData.data.data;

            let gamesDataArray = []

            for (let index in gamesData) {
                let gameData = gamesData[index].attributes;

                let gameDataObj = {};
                gameDataObj["name"] = gameData["Name"];
                gameDataObj["description"] = gameData["Description"];
                gameDataObj["illustration"] = gameData["Illustration"].data.attributes;

                gamesDataArray.push(gameDataObj);
            }

            setGames(gamesDataArray);
        }

        getGames();
    }, []);

    return (
        <div className="explore-games-page-container">
            <h1 className="explore-games-header">Explore Games</h1>
            <GamesCards games={games} />
        </div>
    )
}