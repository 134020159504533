import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as gameNames from '../../constants/gameNames';
import './GameCards.scss';

export const GamesCards = (props) => {

    const navigate = useNavigate();

    const goToGameOverview = useCallback(
        (gameName) => {
            let gameNamesObj = gameNames.gameNames;
            let gameNameKey = Object.keys(gameNamesObj).find(key => gameNamesObj[key] === gameName);
            navigate("/game-overview/" + gameNameKey);
        },
        [navigate]
    );

    return (
        <div className="games-container">
            {props.games.length > 0 && props.games.map((game) =>
                <div className="game-card" key={game.name} onClick={() => { goToGameOverview(game.name) }}>
                    <div className="game-details-container">
                        <div className="illustration-container">
                            <img src={game.illustration.url} alt={game.illustration.alternativeText} className="illustration-img"></img>
                        </div>
                        <div className="game-detail-container">
                            <h2 className="game-name">{game.name}</h2>
                            <p className="game-description">{game.description}</p>
                        </div>
                    </div>
                    <div className="game-action-btn-container">
                        <button className="game-action-btn"></button>
                    </div>
                </div>
            )}
        </div>
    );
}