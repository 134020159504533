import React, { useCallback, useEffect, useState } from 'react';
import * as apiFunctions from '../../../services/apiFunctions';
import './Twine.scss';

export const Twine = (props) => {

    const [twineHTMLData, setTwineHTMLData] = useState("");

    const getTwineHTMLData = useCallback(
        async () => {
            resetTwine();

            let fileName = props.configuration[0].File.data.attributes.url;
            let response = await apiFunctions.getAPIData(fileName);

            if (response.success) {
                let htmlData = response.responseData.data;
                setTwineHTMLData(htmlData);
            }
        },
        [props]
    );

    useEffect(() => {
        getTwineHTMLData();
    }, [getTwineHTMLData]);

    const resetTwine = () => {
        sessionStorage.removeItem("Saved Session");
        setTwineHTMLData("");
    }

    return (
        <div className="twine-container">
            <button onClick={getTwineHTMLData} className="reset-btn">
                <span className="reset-btn-icon"></span>
                <span className="reset-btn-label">Reset</span>
            </button>
            <iframe title="TwineConsentNarrative" srcDoc={twineHTMLData} className="twine-iframe"></iframe>
        </div>
    )
}