import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './PathwayCards.scss';
import * as pathwayNames from '../../constants/pathwayNames';
import comingSoonIcon from '../../assets/utility-icons/coming-soon.svg';

export const PathwayCards = (props) => {

    const navigate = useNavigate();


    const goToPathwayDashboard = useCallback(
        (pathway) => {
            if (pathway.courseCount > 0) {
                let pathwayNamesObj = pathwayNames.pathwayNames;
                let pathwayNameKey = Object.keys(pathwayNamesObj).find(key => pathwayNamesObj[key] === pathway.name);
                navigate("/pathway-dashboard/" + pathwayNameKey);
            }
        },
        [navigate]
    );

    return (
        <div className="pathways-container">
            {props.pathways && props.pathways.length > 0 && props.pathways.map((pathway) =>
                <div onClick={() => { goToPathwayDashboard(pathway) }} className={`pathway-card ${pathway.name === "Sex Education" ? "sex-ed" : ""} ${pathway.name === "Sustainability" ? "sustainability" : ""} ${pathway.name === "Mental Health" ? "mental-health" : ""}  ${pathway.name === "Self Defence" ? "self-defence" : ""}`} key={pathway.name}>
                    {pathway.courseCount <= 0 &&
                        <div className="coming-soon-icon-container">
                            <img src={comingSoonIcon} alt="coming-soon-icon" className="coming-soon-icon"></img>
                        </div>
                    }
                    <div className="pathway-banner">
                        <img src={pathway.illustration.url} alt={pathway.illustration.alternativeText} className="pathway-banner-img"></img>
                    </div>
                    <p className="pathway-name">{pathway.name}</p>
                    <p className="pathway-lesson-count">{pathway.courseCount} Courses</p>
                    <div className="pathway-btn-container">
                        <button className="pathway-btn">
                            <span className="pathway-btn-label">Go to Pathway</span>
                            <span className="pathway-btn-icon"></span>
                        </button>
                    </div>
                </div>
            )}
        </div >
    )
}