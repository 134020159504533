import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/useAuth';
import * as apiFunctions from '../../services/apiFunctions';
import * as url from '../../config/environmentURL';
import './NavigationBar.scss';
import iconLogo from '../../assets/logo/icon-logo.svg';
import userAvatar from '../../assets/illustrations/user-avatar.svg';


export const NavigationBar = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { user, logout } = useAuth();

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [currentPath, setCurrentPath] = useState("");
    const [userDetails, setUserDetails] = useState({});

    const toggleNavBar = () => {
        setIsCollapsed(!isCollapsed);
        props.toggleNavBar(!isCollapsed);
    }

    useEffect(() => {
        async function getUserDetails() {
            let response = await apiFunctions.getAPIData(url.currentURL + "user/" + user);
            if (response.success) {
                let responseData = response.responseData.data.results.userData;
                setUserDetails(responseData);
            }
            else {
                if (response.errorData.response.status === 401) {
                    logout();
                }
            }
        }
        getUserDetails();
        setCurrentPath(location.pathname);
        setIsCollapsed(true);
    }, [location, logout, user]);

    useEffect(() => {

    }, [])

    return (
        <div className={`navigation-bar-container ${isCollapsed ? "collapsed" : ""}`}>
            <img className="icon-logo-mobile" src={iconLogo} alt="icon-logo" onClick={() => { navigate("/") }}></img>
            <div className="navigation-bar-btn">
                <span className="navigation-bar-btn-icon" onClick={toggleNavBar}></span>
            </div>

            <div className="logo-container" onClick={() => { navigate("/") }}>
                <img className="icon-logo" src={iconLogo} alt="icon-logo"></img>
                <span className="app-name">Missed in School</span>
            </div>

            <div className="user-detail-container">
                <div className="user-initials-outer-circle" onClick={() => { navigate("profile") }}>
                    <div className="user-initials-inner-circle">
                        <img src={userAvatar} alt="user-avatar" className="user-avatar-img"></img>
                    </div>
                </div>
                <p className="user-name" onClick={() => { navigate("profile") }}>{userDetails.firstName && userDetails.lastName && userDetails.firstName} {userDetails.lastName}</p>
            </div>

            <div className="nav-options-container">
                <div className="toggle-nav-button" onClick={toggleNavBar}>
                    <span className="toggle-icon"></span>
                </div>

                <div className="nav-options">
                    <div className={`nav-option ${(currentPath === "/dashboard") ? "nav-option--selected" : ""} `} onClick={() => { navigate("dashboard") }}>
                        <div className="nav-option-tooltip">
                            <p className="tooltip-label">Dashboard</p>
                        </div>
                        <span className="nav-icon dashboard-icon"></span>
                        <p className="nav-label">Dashboard</p>
                    </div>

                    <div className={`nav-option ${(currentPath === "/explore-courses") ? "nav-option--selected" : ""} `} onClick={() => { navigate("explore-courses") }}>
                        <div className="nav-option-tooltip">
                            <p className="tooltip-label">Explore</p>
                        </div>
                        <span className="nav-icon explore-icon"></span>
                        <p className="nav-label">Explore</p>
                    </div>

                    <div className={`nav-option ${(currentPath === "/explore-games") ? "nav-option--selected" : ""} `} onClick={() => { navigate("explore-games") }}>
                        <div className="nav-option-tooltip">
                            <p className="tooltip-label">Games</p>
                        </div>
                        <span className="nav-icon games-icon"></span>
                        <p className="nav-label">Games</p>
                    </div>

                    <div className={`nav-option ${(currentPath === "/about-us") ? "nav-option--selected" : ""} `} onClick={() => { navigate("about-us") }}>
                        <div className="nav-option-tooltip">
                            <p className="tooltip-label">About Us</p>
                        </div>
                        <span className="nav-icon about-us-icon"></span>
                        <p className="nav-label">About Us</p>
                    </div>
                </div>

                <div className="nav-options">
                    <div className={`nav-option ${(currentPath === "/profile") ? "nav-option--selected" : ""} `} onClick={() => { navigate("profile") }}>
                        <div className="nav-option-tooltip">
                            <p className="tooltip-label">Profile</p>
                        </div>
                        <span className="nav-icon profile-icon"></span>
                        <p className="nav-label">Profile</p>
                    </div>

                    <div className="nav-option">
                        <div className="nav-option-tooltip">
                            <p className="tooltip-label">Settings</p>
                        </div>
                        <span className="nav-icon settings-icon"></span>
                        <p className="nav-label">Settings</p>
                    </div>

                    <div className="nav-option" onClick={logout}>
                        <div className="nav-option-tooltip">
                            <p className="tooltip-label">Logout</p>
                        </div>
                        <span className="nav-icon logout-icon"></span>
                        <p className="nav-label">Logout</p>
                    </div>
                </div>
            </div>
        </div >
    )
}