import React from 'react';
import { useNavigate } from 'react-router-dom';
import pageNotFoundImg from '../../assets/illustrations/404-page-not-found.svg';
import './PageNotFound.scss';

export const PageNotFound = () => {

    const navigate = useNavigate();

    return (
        <div className="page-not-found-container">
            <img src={pageNotFoundImg} alt="page-not-found-illustration" className="page-not-found-illustration"></img>
            <h1 className="page-not-found-header">Oops! That link does not look right</h1>
            <p className="page-not-found-subheader">Look's like that link does not exist</p>
            <button className="go-back-btn" onClick={() => { navigate("/") }}>
                <span className="go-back-btn-icon"></span>
                <span className="go-back-btn-label">Go back Home</span>
            </button>
        </div>
    )
}