import { createContext, useContext, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import * as apiFunctions from './apiFunctions';
import * as url from '../config/environmentURL';

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = useCallback(
    async (data) => {
      let response = await apiFunctions.postAPIData(url.currentURL + "login", data);

      if (response.success) {
        setUser(data.emailID);
        setToken(response.responseData.data.results.user.token);
      }
      return response;
    },
    [setToken, setUser]
  );

  // call this function to sign out logged in user
  const logout = useCallback(
    async () => {
      let response = await apiFunctions.postAPIData(url.currentURL + "logout");
      if (response.success) {
        setUser(null);
        setToken(null);
        navigate("/", { replace: true });
      }
    },
    [navigate, setUser, setToken]
  );

  const value = useMemo(
    () => ({
      user,
      token,
      login,
      logout
    }),
    [user, login, logout, token]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};